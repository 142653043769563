import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import MainLayout from "../MainLayout";
import CustomTable from "../styles/CustomTable";
import CreateBuildingManager from "../dialogs/CreateBuildingManager";
import Autocomplete from "@mui/material/Autocomplete";
import AsoyContext from "../AsoyContext";
import { useCache } from "../CacheProvider";
const backendUrl = process.env.REACT_APP_API_URL;

const BuildingManager = () => {
  const [buildingManagers, setBuildingManagers] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({});
  const [createManagerOpen, setCreateManagerOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const { get, set, remove } = useCache();

  const { filteredData: asoyData } = useContext(AsoyContext);
  const [fieldErrors, setFieldErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    team: "",
    asoys: "",
  });

  const fetchBuildingManagers = useCallback(async () => {
    const cachedData = get("building_managers");
    if (cachedData) {
      setBuildingManagers(cachedData);
      return;
    }
    try {
      const response = await axios.get(`${backendUrl}/building_manager/`);
      setBuildingManagers(response.data);
      set("building_managers", response.data);
    } catch (error) {
      console.error("Error fetching building managers:", error);
    }
  }, [get, set]);

  const fetchAreasAndTeams = useCallback(async () => {
    try {
      const [teamsResponse] = await Promise.all([
        axios.get(`${backendUrl}/teams/`)
      ]);
      setTeams(teamsResponse.data);
    } catch (error) {
      console.error("Error fetching areas or teams:", error);
    }
  }, []);

  useEffect(() => {
    fetchBuildingManagers();
    fetchAreasAndTeams();
  }, [fetchBuildingManagers, fetchAreasAndTeams]);

  const refreshBuildingManagers = () => {
    remove("building_managers");
    fetchBuildingManagers();
  };

  const handleEditOpen = (manager) => {
    setCurrentEdit({
      ...manager,
      firstName: manager.first_name,
      lastName: manager.last_name,
    });
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setCurrentEdit({});
    setFieldErrors({});
  };

  const handleEditSave = async () => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const errors = {};
    if (!currentEdit.firstName) errors.firstName = "Kenttä vaaditaan";
    if (!currentEdit.lastName) errors.lastName = "Kenttä vaaditaan";
    if (!currentEdit.email) errors.email = "Kenttä vaaditaan";
    else if (!emailRegex.test(currentEdit.email))
      errors.email = "Sähköposti ei ole kelvollinen";
    if (!currentEdit.asoys || currentEdit.asoys.length === 0) errors.asoys = "Kenttä vaaditaan";

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      return;
    }

    try {
      await axios.put(`${backendUrl}/building_manager/${currentEdit.id}/updates/`, {
        first_name: currentEdit.firstName,
        last_name: currentEdit.lastName,
        email: currentEdit.email,
        team: currentEdit.team,
        asoys: currentEdit.asoys,
      });
      refreshBuildingManagers();
      handleEditClose();
    } catch (error) {
      console.error("Error updating building manager:", error);
      // Handle the error if needed
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${backendUrl}/building_manager/${id}/`);
      if (response.status === 204) {
        console.log("Building manager deleted successfully");
        refreshBuildingManagers();
        setEditOpen(false);
      }
    } catch (error) {
      console.error("Error deleting Building manager:", error);
    }
    setFieldErrors({});
  };

  const columns = [
    {
      id: "last_name",
      label: "Nimi",
      render: (row) => `${row.first_name} ${row.last_name}`,
    },
    { id: "email", label: "Sähköposti" },
    {
      id: "team",
      label: "Tiimi",
      render: (row) => row.team?.name,
    },
    {
      id: "asoys",
      label: "Taloyhtiöt",
      render: (row) => row.asoys.map((asoy) => asoy.name).join(", "),
    },
  ];

  return (
    <MainLayout>
      <h1>Isännöitsijähallinta</h1>

      <Button
        variant="contained"
        onClick={() => setCreateManagerOpen(true)}
        sx={{ mb: 2 }}
      >
        Lisää Isännöitsijä{" "}
      </Button>
      <CreateBuildingManager
        open={createManagerOpen}
        onClose={() => {
          setCreateManagerOpen(false);
          refreshBuildingManagers();
        }}
      />
      <CustomTable
        columns={columns}
        rows={buildingManagers}
        onRowClick={handleEditOpen}
      />
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Muokkaa isännöitsijää</DialogTitle>
        <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            label="Etunimi"
            type="text"
            fullWidth
            variant="standard"
            value={currentEdit.firstName || ""}
            onChange={(e) =>
              setCurrentEdit({ ...currentEdit, firstName: e.target.value })
            }
            error={Boolean(fieldErrors.firstName)}
            helperText={fieldErrors.firstName}
          />
          <TextField
            required
            margin="dense"
            label="Sukunimi"
            type="text"
            fullWidth
            variant="standard"
            value={currentEdit.lastName || ""}
            onChange={(e) =>
              setCurrentEdit({ ...currentEdit, lastName: e.target.value })
            }
            error={Boolean(fieldErrors.lastName)}
            helperText={fieldErrors.lastName}
          />
          <TextField
            required
            margin="dense"
            label="Sähköposti"
            type="email"
            fullWidth
            variant="standard"
            value={currentEdit.email || ""}
            onChange={(e) =>
              setCurrentEdit({ ...currentEdit, email: e.target.value })
            }
            error={Boolean(fieldErrors.email)}
            helperText={fieldErrors.email}
          />
          <Autocomplete
            id="team-select"
            options={teams}
            getOptionLabel={(team) => team.name}
            value={currentEdit.team || null}
            onChange={(event, newValue) => {
              setCurrentEdit({ ...currentEdit, team: newValue });
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Tiimi"
                type="text"
                fullWidth
                variant="standard"
                error={Boolean(fieldErrors.team)}
                helperText={fieldErrors.team}
              />
            )}
          />

          <Autocomplete
            multiple
            id="asoy-select"
            options={asoyData || []}
            getOptionLabel={(asoy) => asoy.name}
            value={currentEdit.asoys || []}
            onChange={(event, newValue) =>
              setCurrentEdit({ ...currentEdit, asoys: newValue })
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                variant="standard"
                label="Taloyhtiöt"
                error={Boolean(fieldErrors.asoys)}
                helperText={fieldErrors.asoys}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Peruuta</Button>
          <Button onClick={handleEditSave}>Tallenna</Button>
          <Button onClick={() => handleDelete(currentEdit.id)} color="error">
            Poista
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
};

export default BuildingManager;
