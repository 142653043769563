import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import AuthProvider from "./auth/Authentication";
import theme from "./styles/theme";
import Routes from "./auth/Routes";
import CacheProvider from "./CacheProvider";
// import { cleanupSocket } from './config/WebSocket'; // Comment out or remove this line

const App = () => {
  useEffect(() => {
    // Check if user is logged in when the app is loaded
    const isTokenExpired = () => {
      const token = localStorage.getItem("token");
      if (!token) return true;
      const tokenData = JSON.parse(atob(token.split(".")[1]));
      const expirationTime = tokenData.exp * 1000;
      return Date.now() >= expirationTime;
    };

    if (isTokenExpired()) {
      localStorage.removeItem("token");
    }

    // Cleanup on component unmount
    // return () => {
    //   cleanupSocket();
    // };
    // Comment out the cleanup function or remove it
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CacheProvider>
          <Routes />
        </CacheProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
