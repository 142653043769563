import { createContext, useContext, useEffect, useMemo, useState, useCallback } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Importing jwt-decode for decoding JWT tokens

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken_] = useState(localStorage.getItem("token"));
  const [decodedToken, setDecodedToken] = useState(null); // State to hold the decoded token

  const setToken = useCallback((token) => {
    setToken_(token);
  }, []); // No dependencies, setToken is memoized and won't change on re-renders

  // Function to log the user out and remove the token from storage
  const logout = useCallback(() => {
    setToken_(null); // Update state to reflect logout
    localStorage.removeItem("token"); // Remove token from storage
    delete axios.defaults.headers.common["Authorization"]; // Remove token from default headers
    // You might also want to redirect the user to the login page here,
    // but that might depend on your routing setup
  }, []);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`; // Set token to default headers
      localStorage.setItem("token", token); // Store token in local storage
      setDecodedToken(jwtDecode(token)); // Decode token and set it to state
    } else {
      delete axios.defaults.headers.common["Authorization"]; // Remove token from default headers
      localStorage.removeItem("token"); // Remove token from storage
      setDecodedToken(null); // Reset decoded token when token is removed
    }
  }, [token]);

  const contextValue = useMemo(() => ({ token, setToken, logout, decodedToken }), [token, setToken, logout, decodedToken]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    // If context is undefined, it means useAuth was called outside of the AuthProvider
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthProvider;
