import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import AsoyContext from '../AsoyContext';

const CustomCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const AsoyTable = () => {
  // Get the asoy data and search functionality from the context
  const { filteredData, handleSearchChange } = useContext(AsoyContext);

  return (
    <>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={handleSearchChange}
      />
      <Stack spacing={2}>
        {filteredData.map((asoy) => (
          <Link
            to={`/raportit/${asoy.id}`}
            key={asoy.id}
            style={{ textDecoration: "none" }}
          >
            <CustomCard>
              <CardContent>
                <Typography variant="h5" component="div">
                  {asoy.name}
                </Typography>
              </CardContent>
            </CustomCard>
          </Link>
        ))}
      </Stack>
    </>
  );
};

export default AsoyTable;
export { CustomCard };
