import Box from "@mui/material/Box";
import MainLayout from "../MainLayout";
import AsoyNav from "../navigation/AsoyNav";
import ElementsTableAll from "../datatables/ElementsTableAll";
import MaintenancesAll from "../datatables/MaintenancesAll";

function Home() {
  return (
    <MainLayout>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <h1>Etusivu</h1>
        <AsoyNav
          tabs={[
            { label: "Elementit", panel: <ElementsTableAll /> },
            { label: "Huollot", panel: <MaintenancesAll /> },
          ]}
        />{" "}
      </Box>
    </MainLayout>
  );
}

export default Home;
