import { createTheme } from '@mui/material/styles';

// Create a base theme
const baseTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: '#E1D1C2',
      light: '#F8F2ED',
      dark: '#3B3F63',
      contrastText: '#fffff',
    },
    secondary: {
      main: '#CA9667',
      light: '#A0A2B3',
      dark: '#092341',
      contrastText: '#000000',
    },
    action: {
      hover: '#F8F2ED',
      click: '#E1D1C2',
    }
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '100%',
          [baseTheme.breakpoints.up('md')]: {
            width: '70%',
          },
        },
        root: {
          '& .MuiTextField-root': {
            '& .MuiTextField-standard': {
              variant: 'standard',
            },
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& .MuiButton-root': {
            backgroundColor: 'white', 
            color: '#3B3F63',
            '&:hover': {
              backgroundColor: '#F8F2ED',
            },
          },
        },
      },
    },
  },
});

export default theme;