import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Box,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useCache } from "../CacheProvider";
const backendUrl = process.env.REACT_APP_API_URL;
const CreateTeam = ({ open, onClose }) => {
  const [teamName, setTeamName] = useState("");
  const [areaListId, setAreaListId] = useState("");
  const [areas, setAreas] = useState([]);
  const [manager, setManager] = useState(null);
  const [buildingManagers, setBuildingManagers] = useState([]);
  const [allManagers, setAllManagers] = useState([]);
  const [loadingAreas, setLoadingAreas] = useState(true);
  const [loadingManagers, setLoadingManagers] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({
    teamName: "",
    areaListId: "",
    manager: "",
    buildingManagers: "",
  });
  const { get, set } = useCache();

  useEffect(() => {
    const fetchData = async () => {
      setLoadingAreas(true);
      setLoadingManagers(true);
      
      const buildingManagersData = get("building_managers");
      if (buildingManagersData) {
        setAllManagers(buildingManagersData);
        setLoadingManagers(false);
      } else {
        try {
          const response = await axios.get(`${backendUrl}/building_manager/`);
          setAllManagers(response.data);
          set("building_managers", response.data); // Store data in cache
        } catch (error) {
          console.error("Error fetching building managers:", error);
        } finally {
          setLoadingManagers(false);
        }
      }

      const areaData = get("areas");
      if (areaData) {
        setAreas(areaData);
        setLoadingAreas(false);
      } else {
        try {
          const response = await axios.get(`${backendUrl}/area_lists/`); // Adjust this endpoint as needed
          setAreas(response.data);
          set("areas", response.data); // Store data in cache
        } catch (error) {
          console.error("Error fetching areas:", error);
        } finally {
          setLoadingAreas(false);
        }
      }
    };
    fetchData();
  }, [get, set]);

  const handleSubmit = async () => {
    const errors = {};
    if (!teamName) errors.teamName = "Kenttä vaaditaan";
    if (!manager) errors.manager = "Kenttä vaaditaan";
    if (!areaListId) errors.areaListId = "Kenttä vaaditaan";
    if (buildingManagers.length === 0)
      errors.buildingManagers = "Kenttä vaaditaan";

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      return;
    }

    try {
      const newTeam = {
        name: teamName,
        area_list_id: areaListId,
        manager_id: manager?.id,
        building_manager_ids: buildingManagers.map((bm) => bm.id),
      };

      await axios.post(`${backendUrl}/teams/`, newTeam);
      onClose(); // Close the dialog
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error("Error response data:", error.response.data); // Log detailed error response
      } else {
        console.error("Error creating new team:", error);
      }
    }
  };

  const handleCancel = () => {
    setTeamName("");
    setAreaListId("");
    setManager(null);
    setBuildingManagers([]);
    setFieldErrors({});
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Luo Uusi Tiimi</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            required
            autoFocus
            margin="dense"
            label="Tiimin Nimi"
            type="text"
            fullWidth
            variant="standard"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            error={Boolean(fieldErrors.teamName)}
            helperText={fieldErrors.teamName}
          />
        </Box>
        <Box mb={2}>
          {loadingAreas ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              options={areas}
              getOptionLabel={(option) => option.name} // Adjust according to your areas data structure
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Alue"
                  error={Boolean(fieldErrors.areaListId)}
                  helperText={fieldErrors.areaListId}
                  variant="standard"
                />
              )}
              value={areas.find((area) => area.id === areaListId) || null} // Ensure value is null instead of undefined if not found
              onChange={(event, newValue) => {
                setAreaListId(newValue ? newValue.id : null); // Set to null if newValue is not available
              }}
            />
          )}
        </Box>
        <Box mb={2}>
          {loadingManagers ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              options={allManagers}
              getOptionLabel={(option) =>
                option.first_name + " " + option.last_name
              }
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Manageri"
                  error={Boolean(fieldErrors.manager)}
                  helperText={fieldErrors.manager}
                  variant="standard"
                />
              )}
              value={manager || null} // Ensure value is null instead of undefined if manager is not set
              onChange={(event, newValue) => {
                setManager(newValue);
              }}
            />
          )}
        </Box>
        <Box mb={2}>
          <Autocomplete
            multiple
            options={allManagers}
            getOptionLabel={(option) =>
              option.first_name + " " + option.last_name
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Isännöitsijät"
                error={Boolean(fieldErrors.buildingManagers)}
                helperText={fieldErrors.buildingManagers}
                variant="standard"
              />
            )}
            value={buildingManagers || []} // Ensure value is an empty array instead of undefined for multiple selection
            onChange={(event, newValue) => {
              setBuildingManagers(newValue);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Peruuta</Button>
        <Button onClick={handleSubmit}>Tallenna</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTeam;
