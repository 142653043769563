import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import AsoyContext from "../AsoyContext";
import { useCache } from "../CacheProvider";
import axios from "axios";
const backendUrl = process.env.REACT_APP_API_URL;

const CreateBuildingManager = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    team: "",
    area: "",
    asoys: [],
  });

  const { filteredData: asoyData } = useContext(AsoyContext);
  const { remove } = useCache();
  const [teams, setTeams] = useState([]);
  const [teamId, setTeamId] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    team: "",
    asoys: "",
  });
  const { get, set } = useCache();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAsoysChange = (event, value) => {
    setFormData((prevState) => ({ ...prevState, asoys: value }));
  };

  const handleTeamsChange = (event, value) => {
    setFormData((prevState) => ({ ...prevState, team: value.name }));
  }

  useEffect(() => {
    const fetchData = async () => {
      // Try to get data from cache
      const cachedData = get("teams");
      if (cachedData) {
        setTeams(cachedData);
        return;
      }
      try {
        const [teamResponse] = await Promise.all([
          axios.get(`${backendUrl}/teams/`), // Adjust this endpoint as needed
        ]);

        setTeams(teamResponse.data);
        set("teams", teamResponse.data); // Store data in cache
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [set, get]);

  const handleSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const errors = {};
    
    if (!formData.firstName) errors.firstName = "Kenttä vaaditaan";
    if (!formData.lastName) errors.lastName = "Kenttä vaaditaan";
    if (!formData.email) errors.email = "Kenttä vaaditaan";
    else if (!emailRegex.test(formData.email))
      errors.email = "Sähköposti ei ole kelvollinen";
  
    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      return;
    }
  
    try {
      const buildingManagerData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        team: teamId || null, // Include the team ID if available
        asoys: formData.asoys.map(asoy => asoy.id), // Assuming asoyData contains objects with an 'id' property
      };
  
      await axios.post(`${backendUrl}/building_manager/`, buildingManagerData);
  
      remove("building_managers"); // Forcing a cache refresh
      onClose(); // Close the dialog upon successful submission
    } catch (error) {
      console.error("Error creating building manager:", error.response?.data || error.message);
      if (error.response?.status === 422) {
        setFieldErrors({ form: "Unprocessable Entity. Please check the form data." });
      } else {
        setFieldErrors({ form: "An unexpected error occurred. Please try again later." });
      }
    }
  };
  
  const handleCancel = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      team: "",
      asoys: [],
    });
    setFieldErrors({});
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Lisää Isännöitsijä</DialogTitle>
      <DialogContent>
        <TextField
          required
          autoFocus
          margin="dense"
          label="Etunimi"
          type="text"
          fullWidth
          variant="standard"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          error={Boolean(fieldErrors.firstName)}
          helperText={fieldErrors.firstName}
        />
        <TextField
          required
          margin="dense"
          label="Sukunimi"
          type="text"
          fullWidth
          variant="standard"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          error={Boolean(fieldErrors.lastName)}
          helperText={fieldErrors.lastName}
        />
        <TextField
          required
          margin="dense"
          label="Sähköposti"
          type="email"
          fullWidth
          variant="standard"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={Boolean(fieldErrors.email)}
          helperText={fieldErrors.email}
        />
        <Box mb={2}>  
        <Autocomplete
          options={teams}
          getOptionLabel={(option) => option.name} // Adjust according to your areas data structure
          renderInput={(params) => (
            <TextField
              //required
              {...params}
              label="Tiimi"
              //error={Boolean(fieldErrors.team)}
              //helperText={fieldErrors.team}
              variant="standard"
            />
          )}
          value={teams.find((team) => team.id === teamId) || null} // Ensure value is null instead of undefined if not found
          onChange={(event, newValue) => {
            setTeamId(newValue ? newValue.id : null); // Set to null if newValue is not available
            handleTeamsChange(event, newValue);
          }}
        />
        </Box>
        <Box mb={2}>
        <Autocomplete
          multiple
          id="tags-standard"
          options={asoyData || []}
          getOptionLabel={(asoy) => asoy.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Taloyhtiöt"
              //error={Boolean(fieldErrors.asoys)}
              //helperText={fieldErrors.asoys}
            />
          )}
          onChange={handleAsoysChange}
        />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Peruuta</Button>
        <Button onClick={handleSubmit}>Lisää</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateBuildingManager;
