import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import CustomTable from "../styles/CustomTable";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/Authentication.js";
import CustomPieChart from "../utils/CustomPieChart";
import { useCache } from "../CacheProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../styles/theme";

const backendUrl = process.env.REACT_APP_API_URL;

const ElementsTableAll = () => {
  const [rows, setRows] = useState([]);
  const [pieChartData, setPieChartData] = useState([]); // State for pie chart data
  const navigate = useNavigate();
  const { token } = useAuth();
  const isAuthenticated = !!token;
  const { get, set } = useCache();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchElementData = useCallback(async () => {
    if (!isAuthenticated) return;

    // Try to get data from cache
    let asoyData = get("asoy_due");
    if (!asoyData) {
      try {
        const response = await axios.get(`${backendUrl}/asoy/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Ensure the data is an array before proceeding
        if (Array.isArray(response.data)) {
          asoyData = response.data;
        } else {
          console.error("Expected an array but received:", typeof response.data);
          return;
        }

        set("asoy_due", asoyData);
      } catch (error) {
        console.error("Error fetching element data:", error);
        return;
      }
    }

    setRows(asoyData);

    if (asoyData) {
      // Count occurrences of each 'asoyName'
      const asoyIds = {};
      const asoyCounts = {};
      asoyData.forEach((element) => {
        asoyCounts[element.asoyName] = (asoyCounts[element.asoyName] || 0) + 1;
        asoyIds[element.asoyName] = element.asoyId;
      });

      // Convert the counts object to an array and sort by count descending
      let pieChartData = Object.keys(asoyCounts).map((key) => ({
        id: asoyIds[key],
        label: key,
        value: asoyCounts[key],
      }));
      pieChartData.sort((a, b) => b.value - a.value);
      // Slice the array to keep only the top 5 entries
      pieChartData = pieChartData.slice(0, 5);

      // Update the pie chart data
      setPieChartData(pieChartData);
    }
  }, [isAuthenticated, token, get, set]);

  useEffect(() => {
    fetchElementData();
  }, [fetchElementData]);

  const handleRowClick = (row) => {
    navigate(`/raportit/${row.asoyId}/`); // Adjust the navigation path as needed
  };

  const columns = [
    { id: "element", label: "Elementti" },
    { id: "asoyName", label: "Taloyhtiö" },
    { id: "nextInspectionYear", label: "Tarkistusvuosi" }, // Adjust according to the data structure
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ marginLeft: isSmallScreen ? '0px' : '30px' }}>
        Elinkaaren päässä olevat elementit
      </Typography>
      {pieChartData.length > 0 ? (
        <Box sx={{}}>
          <CustomPieChart data={pieChartData} navigate={navigate} />
        </Box>
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: isSmallScreen ? '-60px' : '0px',
          width: isSmallScreen ? '100vw' : 'auto' // Set width to 100% of viewport width on small screens
        }}>
        <CustomTable
          columns={columns}
          rows={rows}
          onRowClick={handleRowClick}
          hoverBackgroundColor={"#f5f5f5"}
        />
      </Box>
    </Box>
  );
};

export default ElementsTableAll;
