import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";
import { useCache } from "../CacheProvider";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_URL;


const CreateAsoy = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    address_road: "",
    address_number: "",
    address_postal_code: "",
    address_city: "",
    total_shares: 0,
    total_sqr_m: 0,
    business_id: "", // New field for y-tunnus
  });
  const { remove } = useCache();
  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    address_road: "",
    address_number: "",
    address_postal_code: "",
    address_city: "",
    total_shares: "",
    total_sqr_m: "",
    business_id: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    const errors = {};
    if (!formData.name) errors.name = "Kenttä vaaditaan";
    if (!formData.address_road) errors.address_road = "Kenttä vaaditaan";
    if (!formData.address_number) errors.address_number = "Kenttä vaaditaan";
    if (!formData.address_postal_code)
      errors.address_postal_code = "Kenttä vaaditaan";
    if (!formData.address_city) errors.address_city = "Kenttä vaaditaan";
    if (!formData.total_shares) errors.total_shares = "Kenttä vaaditaan";
    if (!formData.total_sqr_m) errors.total_sqr_m = "Kenttä vaaditaan";
    if (!formData.business_id) errors.business_id = "Kenttä vaaditaan";

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    try {
      // Construct payload including y-tunnus
      const payload = {
        name: formData.name,
        //address: `${formData.address_road} ${formData.address_number}, ${formData.address_postal_code} ${formData.address_city}`,
        address_road: formData.address_road,
        address_number: formData.address_number,
        address_postal_code: formData.address_postal_code,
        address_city:formData.address_postal_code,
        total_shares: formData.total_shares,
        total_sqr_m: formData.total_sqr_m,
        business_id: formData.business_id, // Mapping y-tunnus to business_id
      };

      // Making a POST request using axios to create a team
      await axios.post(`${backendUrl}/asoy/`, payload);

      // Close the dialog upon successful submission
      onClose();

      // Forcing a cache refresh
      remove("asoy-all");
    } catch (error) {
      // Log detailed error response if it's an Axios error with a response
      if (axios.isAxiosError(error) && error.response) {
        console.error("Error response data:", error.response.data);
      } else {
        // Log generic error if it's not an Axios error
        console.error("Error creating new team:", error);
      }
    }
    setFieldErrors({});
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Lisää Taloyhtiö</DialogTitle>
      <DialogContent>
        <TextField
          required
          autoFocus
          margin="dense"
          label="Taloyhtiön nimi"
          type="text"
          fullWidth
          variant="standard"
          name="name"
          value={formData.name}
          onChange={handleChange}
          error={Boolean(fieldErrors.name)}
          helperText={fieldErrors.name}
        />
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              margin="dense"
              label="Tie"
              type="text"
              fullWidth
              variant="standard"
              name="address_road"
              value={formData.address_road}
              onChange={handleChange}
              error={Boolean(fieldErrors.address_road)}
              helperText={fieldErrors.address_road}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              margin="dense"
              label="Numero"
              type="text"
              fullWidth
              variant="standard"
              name="address_number"
              value={formData.address_number}
              onChange={handleChange}
              error={Boolean(fieldErrors.address_number)}
              helperText={fieldErrors.address_number}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              margin="dense"
              label="Postinumero"
              type="text"
              fullWidth
              variant="standard"
              name="address_postal_code"
              value={formData.address_postal_code}
              onChange={handleChange}
              error={Boolean(fieldErrors.address_postal_code)}
              helperText={fieldErrors.address_postal_code}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              margin="dense"
              label="Kaupunki"
              type="text"
              fullWidth
              variant="standard"
              name="address_city"
              value={formData.address_city}
              onChange={handleChange}
              error={Boolean(fieldErrors.address_city)}
              helperText={fieldErrors.address_city}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              margin="dense"
              label="Y-Tunnus"
              type="text"
              fullWidth
              variant="standard"
              name="business_id"
              value={formData.business_id}
              onChange={handleChange}
              error={Boolean(fieldErrors.business_id)}
              helperText={fieldErrors.business_id}
            />
          </Grid>
        </Grid>
        <TextField
          required
          margin="dense"
          label="Osakkeiden lkm"
          type="number"
          InputProps={{
            inputProps: { min: 0 },
          }}
          fullWidth
          variant="standard"
          name="total_shares"
          value={formData.total_shares}
          onChange={handleChange}
          error={Boolean(fieldErrors.total_shares)}
          helperText={fieldErrors.total_shares}
        />
        <TextField
          required
          margin="dense"
          label="Neliöt"
          type="number"
          InputProps={{
            inputProps: { min: 0 },
          }}
          fullWidth
          variant="standard"
          name="total_sqr_m"
          value={formData.total_sqr_m}
          onChange={handleChange}
          error={Boolean(fieldErrors.total_sqr_m)}
          helperText={fieldErrors.total_sqr_m}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setFieldErrors({});
            setFormData({
              name: "",
              address_road: "",
              address_number: "",
              address_postal_code: "",
              address_city: "",
              total_shares: 0,
              total_sqr_m: 0,
              business_id: "",
            });
            onClose();
          }}
        >
          Peruuta
        </Button>
        <Button onClick={handleSubmit}>Lisää</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAsoy;
