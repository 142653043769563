import React, { useEffect, useState, useCallback } from 'react';
import { Box, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import SideNavbar from './navigation/Navbar';
import { styled, useMediaQuery } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './auth/Authentication';

const CustomScrollbar = styled('div')(({ theme, maxHeight }) => {
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const toolbarHeight = theme.mixins.toolbar[isXsScreen ? 'xs' : 'sm'] || theme.mixins.toolbar.minHeight;

  return {
    maxHeight: maxHeight ? maxHeight : `calc(100vh - ${toolbarHeight + 10}px)`,
    maxWidth: `100vw`,
    marginTop: `${toolbarHeight + 10}px`,
    marginRight: '2px',
    padding: theme.spacing(2),
    overflowY: 'auto',
    flexGrow: 1,
    '&::-webkit-scrollbar': {
      width: 12,
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.primary.light,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.dark,
      borderRadius: 6,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.dark,
    },
  };
});

const MainLayout = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [sessionAlertOpen, setSessionAlertOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = useCallback( () => {
    logout(); // Removes the token, updates the state, and cleans up headers
    navigate('/login'); // Redirects the user to the login page
  }, [logout, navigate]);


  useEffect(() => {
    const getTokenExpiry = (token) => {
      const decoded = jwtDecode(token);
      return decoded.exp ? new Date(decoded.exp * 1000) : null;
    };

    const scheduleSessionAlert = (expiryTime) => {
      const alertTime = expiryTime - 5 * 60 * 1000; 
      const now = Date.now();
      if (alertTime > now) {
        setTimeout(() => setSessionAlertOpen(true), alertTime - now);
      }
    };
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decoded = jwtDecode(token);
        const isTokenExpired = decoded.exp * 1000 <= Date.now();
        if (isTokenExpired) {
          handleLogout(); // Call handleLogout if the token is expired
        }
      }
    };

    // Run checkTokenExpiration on mount and periodically
    checkTokenExpiration();
    //const intervalId = setInterval(checkTokenExpiration, 60000); // Check every 60 seconds

    const refreshToken = async () => {
      try {
        const response = await axios.post('/refresh/', {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const { access_token } = response.data;
        localStorage.setItem('token', access_token);
        return getTokenExpiry(access_token); // Get the new expiry time
      } catch (error) {
        console.error('Error refreshing token:', error);
        // Handle token refresh failure
      }
    };
    

    const token = localStorage.getItem('token');
    if (token) {
      const expiryTime = getTokenExpiry(token);
      scheduleSessionAlert(expiryTime);
    }

    const handleContinueSession = () => {
      setSessionAlertOpen(false);
      refreshToken().then(newExpiryTime => {
        if (newExpiryTime) {
          scheduleSessionAlert(newExpiryTime);
        }
      });
    };

    // Make handleContinueSession available for onClick event
    window.handleContinueSession = handleContinueSession;

    // Clean up
    return () => {
      window.handleContinueSession = undefined;
    }
  }, [handleLogout]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SideNavbar open={open} setOpen={setOpen} />
      <CustomScrollbar>
        <Box component="main" sx={{ flexGrow: 1, paddingLeft: '32px', paddingRight: '32px' }}>
          {children}
        </Box>
      </CustomScrollbar>
      <Dialog open={sessionAlertOpen} onClose={() => setSessionAlertOpen(false)}>
        <DialogTitle>Session päättyminen</DialogTitle>
        <DialogContent>Sessio on melkein ohi, haluatko jatkaa?</DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>En, kirjaudu ulos</Button>
          <Button onClick={() => window.handleContinueSession?.()}>Kyllä, jatka sivulla</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MainLayout;