import React, { useState, useRef, useEffect, useCallback } from "react";
import IdleTimer from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import axios from "axios"; // Import axios for making HTTP requests

const backendUrl = process.env.REACT_APP_API_URL;
const IdleTimerComponent = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const idleTimerRef = useRef(null);
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(30); // 30 seconds countdown

  const onIdle = () => {
    console.log("User is idle.");
    setIsDialogOpen(true); // Show the dialog
  };

  const handleSilentRefresh = async () => {
    try {
      // Assume '/auth/refresh' is your token refresh endpoint
      const response = await axios.post(`${backendUrl}/refresh`, {
        refresh_token: localStorage.getItem("refreshToken"), // Send the stored refresh token
      });
      const { access_token, refresh_token } = response.data;
      localStorage.setItem("token", access_token); // Update the access token
      if (refresh_token) {
        localStorage.setItem("refreshToken", refresh_token); // Optionally update the refresh token
      }
      console.log("Token refreshed silently");
      return true;
    } catch (error) {
      console.error("Silent refresh failed", error);
      return false;
    }
  };

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken"); // Ensure the refresh token is also removed
    setIsDialogOpen(false); // Close the dialog
    navigate("/login"); // Redirect to the login page
  }, [navigate]);

  useEffect(() => {
    let interval;
    if (isDialogOpen && countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      handleLogout(); // Logout automatically when countdown reaches zero
    }

    // Clear interval on cleanup
    return () => clearInterval(interval);
  }, [isDialogOpen, countdown, handleLogout]);

  const handleContinue = async () => {
    const refreshSuccess = await handleSilentRefresh();
    if (refreshSuccess) {
      setIsDialogOpen(false); // Close the dialog
      setCountdown(30); // Reset countdown when user chooses to continue
      idleTimerRef.current.reset(); // Reset the idle timer
    } else {
      handleLogout(); // If refresh fails, logout the user
    }
  };

  return (
    <>
      <IdleTimer ref={idleTimerRef} timeout={30 * 60 * 1000} onIdle={onIdle} />

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="idle-dialog-title"
        aria-describedby="idle-dialog-description"
      >
        <DialogTitle id="idle-dialog-title">
          Istuntosi on ollut toimeton
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="idle-dialog-description">
            Et ole ollut aktiivinen hetkeen, haluatko jatkaa?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout} color="primary">
            En, kirjaudu ulos
          </Button>
          <Button onClick={handleContinue} color="primary" autoFocus>
            Kyllä, jatka
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IdleTimerComponent;
