import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";
import axios from "axios";
import MainLayout from "../MainLayout";
import CustomTable from "../styles/CustomTable";
import CreateUser from "../dialogs/CreateUser";
import AsoyProvider from "../AsoyProvider"; // Assuming this is the context provider
import { useCache } from "../CacheProvider";
const backendUrl = process.env.REACT_APP_API_URL;

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({});
  const [createUserOpen, setCreateUserOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const { get, set, remove } = useCache();
  const [fieldErrors, setFieldErrors] = useState({
    username: "",
    email: "",
    role: "",
  });

  // Autocomplete value is selected based on currentEdit.role
  const selectedRole = roles.find((role) => role.name === currentEdit.role);

  const fetchUsers = useCallback(async () => {
    // Try to get data from cache
    const cachedData = get("users");
    if (cachedData) {
      setUsers(cachedData);
      return;
    }

    try {
      const response = await axios.get(`${backendUrl}/users/`);
      response.data = response.data.map((user) => ({
        ...user,
        role: user.role.name,
      }));
      setUsers(response.data);
      set("users", response.data); // Store data in cache
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [get, set]);

  const fetchRoles = useCallback(async () => {
    // Try to get data from cache
    const cachedData = get("roles");
    if (cachedData) {
      setRoles(cachedData);
      return;
    }
    try {
      const response = await axios.get(`${backendUrl}/roles/`);
      setRoles(response.data);
      set("roles", response.data); // Store data in cache
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  }, [get, set]);

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, [fetchRoles, fetchUsers]);

  const refreshUsers = async () => {
    remove("users"); // Force fetch new data
    fetchUsers();
  };

  const handleEditOpen = (user) => {
    setCurrentEdit(user);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEditSave = async () => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const errors = {};
    if (!currentEdit.username) errors.username = "Kenttä vaaditaan";
    if (!currentEdit.email) errors.email = "Kenttä vaaditaan";
    else if (!emailRegex.test(currentEdit.email))
      errors.email = "Sähköposti ei ole kelvollinen";
    if (!currentEdit.role) errors.role = "Kenttä vaaditaan";

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      return;
    }
    // Perform save operation here
    handleEditClose();
    setFieldErrors({}); // Clear any previous errors
  };

  const handleDelete = async (id) => {
    try {
      // To be sure that the user is not an admin
      // Find the user by id
      const userToDelete = users.find((user) => user.id === id);

      // Check if the user has the role of "admin"
      if (userToDelete && userToDelete.role === "admin") {
        console.log("Cannot delete user with admin role");
        return;
      }

      const response = await axios.delete(`${backendUrl}/user/${id}/`);
      if (response.status === 204) {
        console.log("User deleted successfully");
        refreshUsers();
        fetchRoles();
        setEditOpen(false); // Close the dialog if it's open
      }
    } catch (error) {
      console.error("Error deleting User:", error);
      // Handle error, potentially updating the UI to reflect the error state
    }
    setFieldErrors({}); // Clear any previous errors
  };

  const handleCreateUser = async () => {
    setCreateUserOpen(false);
    refreshUsers();
    fetchRoles();
  };

  const columns = [
    { id: "username", label: "Käyttäjänimi" },
    { id: "email", label: "Sähköposti" },
    { id: "role", label: "Rooli" },
  ];

  return (
    <AsoyProvider>
      {" "}
      <MainLayout>
        <h1>Käyttäjähallinta</h1>
        <Button
          variant="contained"
          onClick={() => setCreateUserOpen(true)}
          sx={{ mb: 2 }}
        >
          Luo uusi käyttäjä{" "}
        </Button>
        <CreateUser open={createUserOpen} onClose={() => handleCreateUser()} />
        <CustomTable
          columns={columns}
          rows={users}
          onRowClick={handleEditOpen}
        />
        <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
          <DialogTitle>Muokkaa käyttäjää</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <TextField
                required
                autoFocus
                margin="dense"
                label="Käyttäjänimi"
                type="text"
                fullWidth
                variant="standard"
                value={currentEdit.username || ""}
                onChange={(e) =>
                  setCurrentEdit({ ...currentEdit, username: e.target.value })
                }
                error={Boolean(fieldErrors.username)}
                helperText={fieldErrors.username}
              />
            </Box>
            <Box mb={2}>
              <TextField
                required
                margin="dense"
                label="Sähköposti"
                type="email"
                fullWidth
                variant="standard"
                value={currentEdit.email || ""}
                onChange={(e) =>
                  setCurrentEdit({ ...currentEdit, email: e.target.value })
                }
                error={Boolean(fieldErrors.email)}
                helperText={fieldErrors.email}
              />
            </Box>
            <Box mb={2}>
              <Autocomplete
                id="role-select"
                options={roles}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    variant="standard"
                    label="Select Role"
                    error={Boolean(fieldErrors.role)}
                    helperText={fieldErrors.role}
                  />
                )}
                value={selectedRole} // Set selected role
                onChange={(e, newValue) => {
                  setCurrentEdit({
                    ...currentEdit,
                    role: newValue ? newValue.name : "",
                  }); // Assign role name or empty string to currentEdit.role
                }}
                clearable // Allow clearing the selection
                sx={{ marginTop: 2 }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>Peruuta</Button>
            <Button onClick={handleEditSave}>Tallenna</Button>
            {currentEdit.role !== "Admin" && ( // Check if the current user is not an admin
              <Button
                onClick={() => handleDelete(currentEdit.id)}
                color="error"
              >
                Poista
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </MainLayout>
    </AsoyProvider>
  );
};

export default UserManagement;
