import React, { createContext, useReducer, useContext, useEffect } from 'react';

// Define the context
const CacheContext = createContext();

// Define the reducer
const cacheReducer = (state, action) => {
    switch (action.type) {
        case 'SET':
            return { ...state, [action.key]: action.value };
        case 'GET':
            return state[action.key];
        case 'REMOVE':
            const newState = { ...state };
            delete newState[action.key];
            return newState;
        case 'CLEAR_ALL':
            return {};
        default:
            throw new Error(`Unsupported action type: ${action.type}`);
    }
};


// Define the provider
const CacheProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cacheReducer, {});

    // Functions to interact with the cache
    const set = (key, value) => dispatch({ type: 'SET', key, value });
    const get = (key) => state[key];
    const remove = (key) => dispatch({ type: 'REMOVE', key });

    useEffect(() => {
        // Clear cache every 15 minutes
        const clearCacheInterval = setInterval(() => {
            dispatch({ type: 'CLEAR_ALL' });
        }, 15 * 60 * 1000); // 15 minutes in milliseconds

        return () => clearInterval(clearCacheInterval);
    }, []);

    return (
        <CacheContext.Provider value={{ set, get, remove }}>
            {children}
        </CacheContext.Provider>
    );
};

// Define a hook for easy access to the cache
export const useCache = () => {
    const context = useContext(CacheContext);
    if (context === undefined) {
        throw new Error('useCache must be used within a CacheProvider');
    }
    return context;
};

export default CacheProvider;