import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useEffect, useState, useCallback } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useCache } from "../CacheProvider";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_URL;


export default function CreateUser({ open, onClose }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [roles, setRoles] = useState([]);
  const [buildingManagers, setBuildingManagers] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedBuildingManagers, setSelectedBuildingManagers] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
  });
  const { get, set, remove } = useCache();

  const fetchRoles = useCallback(async () => {
    // Try to get roles from cache
    const cachedData = get("roles");
    if (cachedData) {
      setRoles(cachedData);
      return;
    }
    // Logic to fetch roles
    try {
      const response = await axios.get(`${backendUrl}/roles/`);
      setRoles(response.data);
      set("roles", response.data); // Store roles in cache
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  }, [get, set]);

  const fetchBuildingManagers = useCallback(async () => {
    // Try to get building managers from cache
    const cachedData = get("building_managers");
    if (cachedData) {
      setBuildingManagers(
        cachedData.map((manager) => ({
          id: manager.id,
          label: `${manager.first_name} ${manager.last_name}`,
          ...manager,
        }))
      );
      return;
    }
    try {
      const response = await axios.get(`${backendUrl}/building_manager/`);
      setBuildingManagers(
        response.data.map((manager) => ({
          id: manager.id,
          label: `${manager.first_name} ${manager.last_name}`,
          ...manager,
        }))
      );
      set("building_managers", response.data); // Store building managers in cache
    } catch (error) {
      console.error("Failed to fetch building managers:", error);
    }
  }, [get, set]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]); // Fetch roles only on component mount

  useEffect(() => {
    fetchBuildingManagers();
  }, [fetchBuildingManagers]); // Fetch building managers only on component mount

  useEffect(() => {
    if (selectedRole && selectedRole.id === 1) {
      setSelectedBuildingManagers(buildingManagers);
    }
  }, [selectedRole, buildingManagers]);

  const clearForm = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setSelectedRole(null);
    setSelectedBuildingManagers([]);
    setFieldErrors({
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!username) errors.username = "Kenttä vaaditaan";
    if (!email) errors.email = "Kenttä vaaditaan";
    else if (!emailRegex.test(email))
      errors.email = "Sähköposti ei ole kelvollinen";
    if (!password) errors.password = "Kenttä vaaditaan";
    else if (password.length < 6)
      errors.password = "Salasanan pitää olla vähintään 6 merkkiä";
    if (!confirmPassword) errors.confirmPassword = "Kenttä vaaditaan";
    else if (confirmPassword !== password)
      errors.confirmPassword = "Salasanat eivät täsmää";
    if (!selectedRole) errors.role = "Kenttä vaaditaan";
    if (errors.username || errors.email || errors.password || errors.confirmPassword || errors.role)
    {
      setFieldErrors(errors);
      return;
    }
    // Since it's a one-to-one relationship, send a single building manager ID
    const buildingManagerId =
      selectedBuildingManagers.length > 0
        ? selectedBuildingManagers[0].id
        : null;

    const userData = {
      username,
      email,
      password, // Ensure you're handling passwords securely!
      role_list_id: selectedRole ? selectedRole.id : undefined,
      building_manager_list_id: selectedRole.id === 1 ? null : buildingManagerId, // Send the single selected building manager ID
    };

    axios
      .post(`${backendUrl}/users/`, userData)
      .then((response) => {
        console.log(response.data);
        remove("users"); // Remove the cached users data to force a refresh
        onClose(); // Close the dialog upon successful user creation
        clearForm(); // Clear the form fields
      })
      .catch((error) => {
        console.error("Error creating user:", error);
      });
  };

  const handleClose = () => {
    onClose();
    clearForm(); // Clear the form fields
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Luo uusi käyttäjä</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Autocomplete
              id="role-select"
              options={roles}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Valitse rooli"
                  error={Boolean(fieldErrors.role)}
                  helperText={fieldErrors.role && "Kenttä vaaditaan"}
                  variant="standard"
                />
              )}
              value={selectedRole}
              onChange={(event, newValue) => {
                setSelectedRole(newValue);
                setSelectedBuildingManagers([]); // Reset selected building managers when role changes
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              id="username"
              label="Käyttäjänimi"
              value={username}
              fullWidth
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              autoComplete="new-username"
              error={Boolean(fieldErrors.username)}
              helperText={fieldErrors.username}
              variant="standard"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              id="email"
              label="Sähköposti"
              value={email}
              fullWidth
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              name="sähköposti"
              autoComplete="new-email"
              error={Boolean(fieldErrors.email)}
              helperText={fieldErrors.email}
              variant="standard"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              id="password"
              label="Salasana"
              value={password}
              fullWidth
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              name="salasana"
              type="password"
              autoComplete="new-password"
              error={Boolean(fieldErrors.password)}
              helperText={fieldErrors.password}
              variant="standard"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              id="confirmPassword"
              label="Vahvista salasana"
              value={confirmPassword}
              fullWidth
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              name="Vahvista salasana"
              type="password"
              autoComplete="new-password"
              error={Boolean(fieldErrors.confirmPassword)}
              helperText={fieldErrors.confirmPassword}
              variant="standard"
            />
          </Box>
          {selectedRole && selectedRole.id === 1 ? (
            <Typography>Admin hallinnoi kaikkia isännöitsijöitä</Typography>
          ) : (
            <Box mb={2}>
              <Autocomplete
                id="building-manager-select"
                options={buildingManagers}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                value={
                  selectedBuildingManagers.length > 0
                    ? selectedBuildingManagers[0]
                    : null
                }
                onChange={(event, newValue) => {
                  setSelectedBuildingManagers(newValue ? [newValue] : []);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Valitse isännöitsijä tai tekninen isännöitsijä"
                    variant="standard"
                  />
                )}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Peruuta</Button>
          <Button onClick={handleSubmit}>Tallenna</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
