import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Autocomplete,
  TextField,
  IconButton,
  Tooltip,
  TablePagination,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/system";
import FilterIcon from "@mui/icons-material/FilterList";
import theme from "./theme";
import { SwapVert, ArrowDownward, ArrowUpward } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 18,
    position: "relative",
    textAlign: "center",
    paddingRight: theme.spacing(2), // Add padding to make space for filter icon
    [theme.breakpoints.down("md")]: {
      fontSize: 14, // Reduce font size for smaller screens
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    [theme.breakpoints.down("md")]: {
      fontSize: 10, // Reduce font size for smaller screens
    },
  },
}));

const StyledFilterContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
}));

const StyledButtonGroup = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const StyledFilterIcon = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const StyledSortButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledFilterTextField = styled(TextField)({
  marginTop: 8,
  width: "100%",
});

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "hoverBackgroundColor",
})(({ theme, hoverBackgroundColor }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.selected, // Use a different color for odd rows
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: hoverBackgroundColor || theme.palette.primary.main, // Fallback to primary.main if hoverBackgroundColor is not provided
  },
  alignItems: "center",
}));

const CustomTable = ({ columns, rows, onRowClick, hoverBackgroundColor }) => {
  const [filters, setFilters] = useState({});
  const [activeFilters, setActiveFilters] = useState([]); // Track active filters
  const [page, setPage] = useState(0); // Current page index
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortconfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleFilterChange = (columnid, value) => {
    setFilters({ ...filters, [columnid]: value });
  };

  const toggleFilter = (columnid) => {
    setActiveFilters((prevFilters) =>
      prevFilters.includes(columnid)
        ? prevFilters.filter((filter) => filter !== columnid)
        : [...prevFilters, columnid]
    );
  };

  const toggleSort = (columnid) => {
    let direction;

    // If the same column is clicked again, toggle direction
    if (sortconfig.key === columnid) {
      direction = sortconfig.direction === "asc" ? "desc" : "asc";
    } else {
      direction = "desc"; // Default sorting direction for a new column
    }

    setSortConfig({ key: columnid, direction });
  };

  const sortedRows = [...rows].sort((a, b) => {
    const valueA = a[sortconfig.key];
    const valueB = b[sortconfig.key];

    // Check if the values are numbers and parse them if they are
    const isNumeric = (value) => !isNaN(value) && !isNaN(parseFloat(value));
    const finalValueA = isNumeric(valueA) ? parseFloat(valueA) : valueA;
    const finalValueB = isNumeric(valueB) ? parseFloat(valueB) : valueB;

    if (sortconfig.direction === "asc") {
      return finalValueA > finalValueB ? 1 : -1;
    } else {
      return finalValueA < finalValueB ? 1 : -1;
    }
  });

  const getUniqueValues = (columnid) => {
    const uniqueValues = new Set();

    if (columnid === "bm_name") {
      // For the "name" column, combine first and last names to create unique full name options
      rows.forEach((row) => {
        const fullName = `${row.first_name} ${row.last_name}`;
        if (fullName.trim()) uniqueValues.add(fullName); // Add fullName only if it's not empty
      });
    } else {
      // For other columns, use the existing logic with additional checks
      rows.forEach((row) => {
        const cellValue = row[columnid];
        if (Array.isArray(cellValue)) {
          cellValue.forEach((value) => {
            if (value && typeof value === "object" && "name" in value) {
              uniqueValues.add(value.name);
            } else if (value) {
              uniqueValues.add(value);
            }
          });
        } else {
          if (
            cellValue &&
            typeof cellValue === "object" &&
            "name" in cellValue
          ) {
            uniqueValues.add(cellValue.name);
          } else if (cellValue) {
            uniqueValues.add(cellValue);
          }
        }
      });
    }

    return uniqueValues.size > 0
      ? Array.from(uniqueValues)
      : ["Ei vaihtoehtoja"];
  };

  const filterRows = (row) => {
    for (const columnid in filters) {
      const filterValue = filters[columnid];
      if (!filterValue) continue; // Skip if filterValue is empty

      let cellValue = row[columnid];
      if (cellValue === undefined || cellValue === null) continue; // Skip if filterValue is undefined or null

      if (Array.isArray(cellValue)) {
        // If the cell value is an array, check if any value in the array matches the filter
        const matches = cellValue.some((val) => {
          const value = typeof val === "object" ? val.name : val; // Adjust based on your data structure
          return value
            .toString()
            .toLowerCase()
            .includes(filterValue.toString().toLowerCase());
        });
        if (!matches) return false;
      } else {
        // Handle non-array values (e.g., simple strings)
        if (
          !cellValue
            .toString()
            .toLowerCase()
            .includes(filterValue.toString().toLowerCase())
        ) {
          return false;
        }
      }
    }
    return true;
  };


  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow hoverBackgroundColor={hoverBackgroundColor}>
              {columns.map((column) => (
                <StyledTableCell key={column.id}>
                  <div>
                    <span align="center">{column.label}</span>
                    <StyledFilterContainer>
                      <StyledButtonGroup>
                        <Tooltip title={`Suodata ${column.label}`}>
                          <StyledFilterIcon
                            onClick={() => toggleFilter(column.id)}
                            size="small"
                          >
                            <FilterIcon />
                          </StyledFilterIcon>
                        </Tooltip>
                        <Tooltip title={`Järjestä ${column.label}`}>
                          <StyledSortButton
                            onClick={() => toggleSort(column.id)}
                            size="small"
                            sortconfig={sortconfig}
                            columnid={column.id}
                          >
                            {sortconfig.key === column.id ? (
                              sortconfig.direction === "asc" ? (
                                <ArrowUpward />
                              ) : (
                                <ArrowDownward />
                              )
                            ) : (
                              <SwapVert />
                            )}
                          </StyledSortButton>
                        </Tooltip>
                      </StyledButtonGroup>
                      {activeFilters.includes(column.id) && ( // Only render the filter when it's active
                        <Autocomplete
                          value={filters[column.id] || ""}
                          onChange={(event, newValue) =>
                            handleFilterChange(column.id, newValue)
                          }
                          options={getUniqueValues(column.id)}
                          fullWidth
                          noOptionsText="Ei vaihtoehtoja"
                          sx={{
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: "black", // Change label text color to black when focused
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "black",
                            },
                          }}
                          renderInput={(params) => (
                            <StyledFilterTextField
                              {...params}
                              variant="outlined"
                              margin="dense"
                              label={`Suodata ${column.label}`}
                              textAlign="center"
                            />
                          )}
                        />
                      )}
                    </StyledFilterContainer>
                  </div>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {sortedRows
              .filter(filterRows)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <StyledTableRow
                  key={row.uniqueId} // Use uniqueId as the key
                  hover
                  hoverBackgroundColor={theme.palette.action.hover}
                  onClick={() => onRowClick(row)}
                  sx={{ cursor: "pointer" }}
                >
                  {columns.map((column) => (
                    <StyledTableCell key={column.id} align="center">
                      {column.render ? column.render(row) : row[column.id]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={rows.filter(filterRows).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
        labelRowsPerPage="Rivejä per sivu:" // Rows per page in Finnish
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} / ${count !== -1 ? count : `enemmän kuin ${to}`}`
        } // Displayed rows label in Finnish
        nexticonbuttontext="Seuraava sivu" // Next page button text in Finnish
        backiconbuttontext="Edellinen sivu" // Previous page button text in Finnish
      />
    </div>
  );
};

export default CustomTable;
