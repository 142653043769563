import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useParams } from "react-router-dom";
import CustomTable from "../styles/CustomTable";
import { useCache } from "../CacheProvider";
import { useMediaQuery } from "@mui/material";
import theme from "../styles/theme";

const backendUrl = process.env.REACT_APP_API_URL;

function Maintenances() {
  const [rows, setRows] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({
    elementId: "",
    element: "",
    maintInterval: "",
    description: "", // Add description to the initial state
  });
  let { asoyId } = useParams();
  const { get, set, remove } = useCache();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchInspectionData = useCallback(async () => {
    const cachedData = get(`asoy_${asoyId}_maintenance_due`);
    if (cachedData) {
      setRows(adjustNextInspectionYear(cachedData));
      return;
    }

    try {
      const response = await axios.get(
        `${backendUrl}/asoy/${asoyId}/maintenance-due/`
      );
      const adjustedData = adjustNextInspectionYear(response.data);
      setRows(
        adjustedData.map((item) => ({
          ...item,
          description: item.description || "", // Add description field
        }))
      );
      set(`asoy_${asoyId}_maintenance_due`, adjustedData);
      console.log("Fetched maintenance data:", response.data);
    } catch (error) {
      console.error("Error fetching maintenance due data:", error);
    }
  }, [asoyId, get, set]);

  const adjustNextInspectionYear = (data) => {
    const currentYear = new Date().getFullYear();
    return data.map((item) => {
      if (item.maintInterval === 1) {
        return {
          ...item,
          nextInspectionYear: currentYear,
        };
      }
      return item;
    });
  };

  useEffect(() => {
    fetchInspectionData();
  }, [fetchInspectionData]);

  const handleEditOpen = (row) => {
    setCurrentEdit(row);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEditSave = async () => {
    const interval = parseInt(currentEdit.maintInterval, 10);
    if (isNaN(interval) || interval < 0) {
      console.error("Invalid maintenance interval:", interval);
      return;
    }

    try {
      const payload = {
        maint_interval: interval,
        description: currentEdit.description,  // Ensure this matches the expected schema
      };
      console.log("Payload being sent:", payload);

      const response = await axios.put(
        `${backendUrl}/maintenances/${currentEdit.elementId}/update-interval/`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const updatedRows = rows.map((row) => {
          if (row.elementId === currentEdit.elementId) {
            return {
              ...row,
              maintInterval: interval,
              description: currentEdit.description,
            };
          }
          return row;
        });
        setRows(adjustNextInspectionYear(updatedRows));
        refreshData();
        setEditOpen(false);
      }
    } catch (error) {
      console.error("Error updating maintenance interval:", error);
    }
  };

  const handleCheckboxChange = async (event, elementId) => {
    event.stopPropagation();

    try {
      // Send the request to the backend to toggle the status
      const response = await axios.patch(
        `${backendUrl}/maintenances/${elementId}/toggle-status/`
      );

      // If the request is successful, update the state with the new status
      const updatedRows = rows.map((row) => {
        if (row.elementId === elementId) {
          return { ...row, tehty: response.data.element.maint_done };
        }
        return row;
      });

      setRows(updatedRows);
      refreshData();
    } catch (error) {
      console.error("Failed to update maintenance status:", error);
      // Optionally, display an error message to the user
    }
  };

  const refreshData = async () => {
    remove(`asoy_${asoyId}_maintenance_due`);
    fetchInspectionData();
  };

  const columns = [
    { id: "element", label: "Elementit" },
    { id: "maintInterval", label: "Huoltoväli (Vuosia)", align: "right" },
    { id: "nextInspectionYear", label: "Seuraava huolto", align: "right" },
    {
      id: "description",
      label: "Selitys", // "description" in Finnish
      align: "left",
    },
    {
      id: "tehty",
      label: "Tehty",
      align: "right",
      render: (row) => (
        <Checkbox
          checked={row.tehty}
          onClick={(event) => handleCheckboxChange(event, row.elementId)}
          sx={{ color: "black", "&.Mui-checked": { color: "black" } }}
        />
      ),
    },
  ];

  return (
    <Box sx={{ display: "flex", marginLeft: isSmallScreen ? "-60px" : "0px" }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <CustomTable
          columns={columns}
          rows={rows}
          onRowClick={handleEditOpen}
        />

        <Dialog open={editOpen} onClose={handleEditClose}>
          <DialogTitle>Muokkaa huoltoväliä</DialogTitle>
          <DialogContent>
            <Box mb={2} mt={2}>
              <InputLabel id="element">Elementti</InputLabel>{" "}
              <TextField
                autoFocus
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                value={currentEdit.element}
                InputProps={{ readOnly: true }}
              />
            </Box>
            <Box mb={2}>
              <InputLabel required id="year-select-label">
                Huoltoväli (vuosia)
              </InputLabel>{" "}
              <Select
                value={currentEdit.maintInterval}
                variant="standard"
                onChange={(e) =>
                  setCurrentEdit({
                    ...currentEdit,
                    maintInterval: e.target.value,
                  })
                }
                displayEmpty
                fullWidth
              >
                {Array.from(new Array(100), (val, index) => 1 + index).map(
                  (year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  )
                )}
              </Select>
            </Box>
            <Box mb={2}>
              <InputLabel id="description">Selitys</InputLabel>{" "}
              <TextField
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                value={currentEdit.description}  // Ensure the description field is properly used
                onChange={(e) =>
                  setCurrentEdit({
                    ...currentEdit,
                    description: e.target.value,
                  })
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>Peru</Button>
            <Button onClick={handleEditSave}>Tallenna</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default Maintenances;
