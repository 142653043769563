import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CustomTable from "../styles/CustomTable"; // Import CustomTable component
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { useParams } from "react-router-dom";
import { InputLabel } from "@mui/material";
import { useCache } from "../CacheProvider";
import { useMediaQuery } from "@mui/material";
import theme from "../styles/theme";
const backendUrl = process.env.REACT_APP_API_URL;

function Inspections() {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [editRowData, setEditRowData] = useState({});
  const { asoyId } = useParams();
  const [asoys] = useState([]);
  const [selectedAsoy] = useState("");
  const [selectedStructuralElement, setSelectedStructuralElement] =
    useState("");
  const [elements, setElements] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const initialFormState = {
    selectedElement: "",
    year: "",
    description: "",
    contractor: "",
  };
  const [formData, setFormData] = useState(initialFormState);
  const { get, set, remove } = useCache();
  const [fieldErrors, setFieldErrors] = useState({
    element: "",
    structuralElement: "",
    year: "",
    description: "",
    contractor: "",
  });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchElements = useCallback(async () => {
    let cachedData = get(`asoy_${asoyId}`);
    let structElementsData = cachedData ? cachedData.structural_elements : null;
    if (!structElementsData) {
      try {
        const response = await axios.get(
          `${backendUrl}/asoy/${asoyId}/elements/`
        );
        structElementsData = response.data;
        set(`asoy_${asoyId}`, { structural_elements: structElementsData }); // Store data in cache
      } catch (error) {
        console.error("Error fetching elements:", error);
        return;
      }
    }
    let elementsData = [];
    if (structElementsData) {
      structElementsData.forEach((element) => {
        element.elements.forEach((el) => {
          elementsData.push(el);
        });
      });
    }

    // Sort elementsData alphabetically by name or another property
    elementsData.sort((a, b) => a.name.localeCompare(b.name)); // Replace 'name' with the appropriate property if necessary

    setElements(elementsData);
  }, [asoyId, get, set]);

  const fetchExaminations = useCallback(async () => {
    // Try to get data from cache
    let examinationsData = get("examinations");
    if (!examinationsData) {
      try {
        const response = await axios.get(`${backendUrl}/examination/`);
        examinationsData = response.data;
        set("examinations", examinationsData); // Store data in cache
      } catch (error) {
        console.error("Error fetching examinations:", error);
        return;
      }
    }
    if (examinationsData) {
      const formattedData = examinationsData.map((item) => {
        return {
          ...item,
          element: item.element?.name || "No name",
        };
      });
      console.log("Formatted data for table:", formattedData);
      setRows(formattedData);
    }
  }, [get, set]);

  useEffect(() => {
    fetchExaminations();
    fetchElements();
  }, [fetchElements, fetchExaminations]);

  const refreshExaminations = () => {
    remove("examinations"); // Force refetching the data
    fetchExaminations();
  };

  const handleEditOpen = (row) => {
    setEditRowData(row); // Set the row data to be edited
    setEditOpen(true); // Open the edit dialog
  };

  const handleEditSave = async () => {
    const errors = {};
    if (!editRowData.element_id) errors.element = "Kenttä vaaditaan";
    if (!editRowData.year) errors.year = "Kenttä vaaditaan";
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const updatePayload = {
      element_id: editRowData.element_id,
      year: editRowData.year,
      description: editRowData.description,
      contractor: editRowData.contractor,
    };

    console.log("Sending update payload:", updatePayload);

    try {
      await axios.put(
        `${backendUrl}/examination/${editRowData.id}/`,
        updatePayload
      );
      refreshExaminations();
      setEditOpen(false); // Close the dialog after saving
    } catch (error) {
      console.error("Error updating examination:", error);
      // Handle error
    }
    resetFieldErrors(); // Reset field errors after submission
  };

  const handleAddRow = async () => {
    const errors = {};
    if (!formData.selectedElement) errors.element = "Kenttä vaaditaan";
    if (!formData.structuralElement && selectedAsoy)
      errors.structuralElement = "Kenttä vaaditaan";
    if (!formData.year) errors.year = "Kenttä vaaditaan";
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    console.log("Sending data to /examination/ endpoint:", formData);
    try {
      await axios.post(`${backendUrl}/examination/`, {
        ...formData,
        element_id: formData.selectedElement, // Use the selected element from formData
        asoy_id: asoyId, // Keep using asoyId from state or URL params
      });
      refreshExaminations(); // Refresh the data
      resetForm(); // Reset the form data
      setOpen(false); // Close the dialog
    } catch (error) {
      console.error("Error adding new examination:", error);
      // Handle error, possibly by showing an error message to the user
    }
    resetForm(); // Reset form data after submission
    resetFieldErrors(); // Reset field errors after submission
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${backendUrl}/examination/${id}/`);
      if (response.status === 204) {
        console.log("Examination deleted successfully");
        refreshExaminations(); // Refresh the data after deletion
        setEditOpen(false); // Close the dialog if it's open
      }
    } catch (error) {
      console.error("Error deleting examination:", error);
      // Handle error, potentially updating the UI to reflect the error state
    }
    resetFieldErrors(); // Reset field errors after submission
  };

  // Function to reset the form data to initial state
  const resetForm = () => {
    setFormData(initialFormState);
  };

  const resetFieldErrors = () => {
    setFieldErrors({
      element: "",
      structuralElement: "",
      year: "",
      description: "",
      contractor: "",
    });
  };

  const handleClose = () => {
    setOpen(false);
    resetForm(); // Reset the form data
    resetFieldErrors(); // Reset the field errors
  };

  const columns = [
    { id: "element", label: "Elementti", align: "left" }, // Use just "element" to match the row data structure
    { id: "year", label: "Vuosi", align: "right" },
    { id: "description", label: "Kuvaus hankkeesta", align: "right" },
    { id: "contractor", label: "Urakoitsija", align: "right" },
  ];

  return (
    <Box sx={{ display: "flex", marginLeft: isSmallScreen ? "-60px" : "0px" }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <CustomTable
          columns={columns}
          rows={rows}
          onRowClick={handleEditOpen}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            mt: 2,
          }}
        >
          <Dialog open={editOpen} onClose={() => setEditOpen(false)}>
            <DialogTitle>Muokkaa Tutkimusta</DialogTitle>
            <DialogContent>
              <Box mb={2} mt={2}>
                <InputLabel required id="element">
                  Elementti
                </InputLabel>{" "}
                <TextField
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={editRowData.element || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box mb={2}>
                <InputLabel required id="year-edit-label">
                  Vuosi
                </InputLabel>{" "}
                <Select
                  value={editRowData.year || ""}
                  onChange={(e) =>
                    setEditRowData({ ...editRowData, year: e.target.value })
                  }
                  displayEmpty
                  fullWidth
                  variant="standard"
                >
                  {Array.from(
                    new Array(50),
                    (val, index) => new Date().getFullYear() - index
                  ).map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box mb={2}>
                <InputLabel id="description-label">
                  Kuvaus hankkeesta
                </InputLabel>{" "}
                <TextField
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={editRowData.description || ""}
                  onChange={(e) =>
                    setEditRowData({
                      ...editRowData,
                      description: e.target.value,
                    })
                  }
                  error={Boolean(fieldErrors.description)}
                  helperText={fieldErrors.description}
                />
              </Box>
              <Box mb={2}>
                <InputLabel id="contractor-label">Urakoitsija</InputLabel>{" "}
                <TextField
                  margin="dense"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={editRowData.contractor || ""}
                  onChange={(e) =>
                    setEditRowData({
                      ...editRowData,
                      contractor: e.target.value,
                    })
                  }
                  error={Boolean(fieldErrors.contractor)}
                  helperText={fieldErrors.contractor}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setEditOpen(false);
                  resetFieldErrors();
                }}
              >
                Peruuta
              </Button>
              <Button onClick={handleEditSave}>Tallenna</Button>
              <Button
                onClick={() => handleDelete(editRowData.id)}
                color="error"
              >
                Poista
              </Button>
            </DialogActions>
          </Dialog>
          <Button variant="contained" onClick={() => setOpen(true)}>
            LISÄÄ UUSI
          </Button>
        </Box>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Uusi Tutkimus</DialogTitle>
          <DialogContent>
            <Box mb={2} mt={2}>
              <InputLabel required id="element-select-label">
                Elementti
              </InputLabel>{" "}
              <Select
                value={formData.selectedElement}
                onChange={(e) =>
                  setFormData({ ...formData, selectedElement: e.target.value })
                }
                fullWidth
                variant="standard"
                error={Boolean(fieldErrors.element)}
                helperText={fieldErrors.element}
              >
                {elements.map((element) => (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box mb={2}>
              {selectedAsoy && (
                <>
                  <InputLabel required id="structelement-select-label">
                    Rakenneosa
                  </InputLabel>{" "}
                  <Select
                    value={selectedStructuralElement}
                    onChange={(e) =>
                      setSelectedStructuralElement(e.target.value)
                    }
                    displayEmpty
                    fullWidth
                    variant="standard"
                    sx={{ mt: 2 }} // Add margin top for spacing
                    error={Boolean(fieldErrors.structuralElement)}
                    helperText={fieldErrors.structuralElement}
                  >
                    <MenuItem disabled value="">
                      <em>Valitse Rakenneosa</em>
                    </MenuItem>
                    {asoys
                      .find((asoy) => asoy.id === selectedAsoy)
                      ?.structural_elements?.map((element) => (
                        <MenuItem key={element.id} value={element.id}>
                          {element.name}
                        </MenuItem>
                      ))}
                  </Select>
                </>
              )}
            </Box>
            <Box mb={2}>
              <InputLabel required id="year-select-label">
                Vuosi
              </InputLabel>{" "}
              <Select
                value={formData.year}
                onChange={(e) =>
                  setFormData({ ...formData, year: e.target.value })
                }
                displayEmpty
                fullWidth
                variant="standard"
                error={Boolean(fieldErrors.year)}
                helperText={fieldErrors.year}
              >
                {Array.from(
                  new Array(50),
                  (val, index) => new Date().getFullYear() - index
                ).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box mb={2}>
              <InputLabel id="new-describtion-label">
                Kuvaus hankkeesta
              </InputLabel>{" "}
              <TextField
                margin="dense"
                id="description"
                type="text"
                fullWidth
                variant="standard"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                error={Boolean(fieldErrors.description)}
                helperText={fieldErrors.description}
              />
            </Box>
            <Box mb={2}>
              <InputLabel id="new-contractor-label">Urakoitsija</InputLabel>{" "}
              <TextField
                margin="dense"
                id="contractor"
                type="text"
                fullWidth
                variant="standard"
                value={formData.contractor}
                onChange={(e) =>
                  setFormData({ ...formData, contractor: e.target.value })
                }
                error={Boolean(fieldErrors.contractor)}
                helperText={fieldErrors.contractor}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Peruuta</Button>
            <Button onClick={handleAddRow}>Lisää</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default Inspections;
