import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import CustomTable from "../styles/CustomTable";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useAuth } from "../auth/Authentication.js";
import { useCache } from "../CacheProvider.js";
import logo from "../icons/kontu3.png";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMediaQuery } from "@mui/material";
import theme from "../styles/theme";

const backendUrl = process.env.REACT_APP_API_URL;


// Table component for displaying single asoy's elements data
const ElementsTable = ({ asoyId }) => {
  const [rows, setRows] = useState([]);
  const [asoyDetails, setAsoyDetails] = useState(null);
  const { token } = useAuth(); // Correctly extract 'token' from useAuth
  const { get, set } = useCache();
  const [selectedRows, setSelectedRows] = useState([]);
  const [openKPTSDialog, setOpenKPTSDialog] = useState(false);
  const [formData, setFormData] = useState({
    info: "",
    year: "",
  });
  const [examinations, setExaminations] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedExaminations, setSelectedExaminations] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [yearError, setYearError] = useState("");
  const [actionError, setActionError] = useState("");
  const [localChanges, setLocalChanges] = useState({});

  const fetchExaminations = useCallback(async () => {
    // Try to get data from cache
    let examinationsData = get("examinations");
    if (!examinationsData) {
      try {
        const response = await axios.get(`${backendUrl}/examination/`);
        examinationsData = response.data;
        set("examinations", examinationsData); // Store data in cache
      } catch (error) {
        console.error("Error fetching examinations:", error);
        return;
      }
    }
    if (examinationsData) {
      const formattedData = examinationsData.map((item) => {
        return {
          ...item,
          element: item.element?.name || "No name",
        };
      });
      console.log("Formatted data for table:", formattedData);
      setExaminations(formattedData);
    }
  }, [get, set]);

  useEffect(() => {
    fetchExaminations();
  }, [fetchExaminations]);

  const fetchElementData = useCallback(async () => {
    if (!asoyId) return; // Exit if asoyId is not provided

    let elementsData = get(`asoy_${asoyId}_elements_repair`);
    if (elementsData) {
      setRows(elementsData);
      return;
    }

    try {
      const response = await axios.get(`${backendUrl}/asoy/${asoyId}/elements-repair/`, {
        headers: {
          Authorization: `Bearer ${token}`, // Make sure token is being correctly fetched and used
        },
      });

      // Use elementId to check for uniqueness
      const elementIds = new Set(response.data.map((row) => row.elementId));
      if (elementIds.size !== response.data.length) {
        console.error("Duplicate element IDs found in the fetched rows.");
        // Handle the duplicate ID situation, e.g., by notifying the user
        return;
      }

      setRows(response.data); // The response data is already in the format needed for the table
      set(`asoy_${asoyId}_elements_repair`, response.data); // Store data in cache
    } catch (error) {
      console.error("Error fetching element data:", error);
    }
  }, [asoyId, token, get, set]);

  const handleSelectRow = (elementId) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = prevSelectedRows.includes(elementId)
        ? prevSelectedRows.filter((id) => id !== elementId)
        : [...prevSelectedRows, elementId];

      // Save the updated selection state to the cache
      set(`asoy_${asoyId}_selectedRows`, newSelectedRows);

      return newSelectedRows;
    });
  };

  const fetchAsoyDetails = useCallback(async () => {
    if (!asoyId || !token) return; // Exit if asoyId or token is not provided

    // Try to get data from cache
    const asoyData = get(`asoy_${asoyId}`);
    if (asoyData) {
      setAsoyDetails(asoyData);
      return;
    }
    try {
      const response = await axios.get(`${backendUrl}/asoy/${asoyId}/`, {
        headers: {
          Authorization: `Bearer ${token}`, // Use the token for authorization
        },
      });
      setAsoyDetails(response.data); // Assuming the response contains asoy details including its name
      set(`asoy_${asoyId}`, response.data); // Store data in cache
    } catch (error) {
      console.error("Error fetching asoy details:", error);
    }
  }, [asoyId, token, get, set]);

  useEffect(() => {
    // Attempt to retrieve the selection state from the cache
    const cachedSelectedRows = get(`asoy_${asoyId}_selectedRows`);
    if (cachedSelectedRows) {
      setSelectedRows(cachedSelectedRows);
    }

    fetchElementData();
    fetchAsoyDetails();
  }, [fetchElementData, fetchAsoyDetails, asoyId, get]); // Add 'get' and 'asoyId' to the dependencies array, fetchAsoyDetails]);

  const columns = [
    {
      id: "select",
      label: "Valitse",
      render: (row) => (
        <Checkbox
          checked={selectedRows.includes(row.elementId)}
          onChange={() => handleSelectRow(row.elementId)}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    },
    { id: "element", label: "Elementti" },
    { id: "action", label: "Suunniteltu toimenpide" },
    { id: "plannedYear", label: "Suunniteltu vuosi" },
    { id: "yearsLeft", label: "Laskennallista ikää jäljellä" },
  ];

  const handleKPTSOpen = () => {
    setOpenKPTSDialog(true);
  };

  const handleCloseKPTSDialog = () => {
    setOpenKPTSDialog(false);
    setFormData({
      info: "",
      year: "",
    });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setYearError("");
    setActionError("");
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    const year = selectedRow?.plannedYear;
    const action = selectedRow?.action;

    if (!action) {
      setActionError("Toimenpide on pakollinen");
    }
    if (!year) {
      setYearError("Vuosi on pakollinen");
    }
    if (action && year) {
      setYearError("");
      setActionError("");
      setDialogOpen(false);

      // Update the local changes with the new data
      setLocalChanges((prevChanges) => ({
        ...prevChanges,
        [selectedRow.elementId]: { action: action, plannedYear: year },
      }));
    }
  };

  const handleDelete = () => {
    // Update the selected row in local changes
    const newLocalChanges = { ...localChanges };
    newLocalChanges[selectedRow.elementId] = {
      ...newLocalChanges[selectedRow.elementId],
      action: "",
      plannedYear: "",
    };

    // Update the selected row in rows
    const newRows = rows.map((row) =>
      row.elementId === selectedRow.elementId
        ? { ...row, action: "", plannedYear: "" }
        : row
    );

    setLocalChanges(newLocalChanges);
    setRows(newRows);
    setDialogOpen(false);
  };

  const generatePdf = () => {
    setOpenSnackbar(true);

    const doc = new jsPDF();

    const img = new Image();
    img.src = logo;
    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      // Set the canvas size to the image size divided by 8
      canvas.width = img.width / 8;
      canvas.height = img.height / 8;

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Get the data URL of the resized image
      const resizedImage = canvas.toDataURL("image/png");

      // Add the resized image to the PDF
      doc.addImage(resizedImage, "PNG", 165, 2, 38, 14);

      // Add the headers and the text at the beginning of the PDF
      doc.setFontSize(20);
      doc.text(
        "ASUNTO-OSAKEYHTIÖN HALLITUKSEN \nTEETTÄMÄ KUNNOSSAPITOTARVESELVITYS",
        15,
        15
      );
      doc.setFontSize(12);
      doc.text(`Yhtiö: ${asoyDetails.name}`, 15, 35);
      doc.text(`Y-tunnus: ${asoyDetails.business_id}`, 15, 42);
      doc.text(
        `Osoite: ${asoyDetails.address_road} ${asoyDetails.address_number}`,
        15,
        49
      );
      doc.text(`Päiväys: ${new Date().toLocaleDateString("fi-FI")}`, 15, 56);
      doc.setFontSize(10);
      doc.text(
        "Asunto-osakeyhtiölain (1599/2009) mukaan asunto-osakeyhtiön hallituksen on esitettävä vuosittain varsinaisessa \nyhtiökokouksessa kirjallinen selvitys tarpeesta sellaiseen yhtiön rakennusten ja kiinteistöjen kunnossapitoon \nyhtiökokousta seuraavien viiden vuoden aikana, joka vaikuttaa olennaisesti osakehuoneiston käyttämiseen, \nyhtiövastikkeeseen tai muihin osakehuoneiston käytöstä aiheutuviin kustannuksin. Lisäksi hallituksen on annettava \nkirjallinen selvitys yhtiössä suoritetuista huomattavista kunnossapito- ja muutostöistä ja niiden tekoaiankohdista.",
        15,
        70
      );
      doc.text(
        "Yhtiökokous merkitsee hallituksen selvityksen tiedoksi. Käynnistettävien toimenpiteiden suunnittelusta, toteutuksesta \nja rahoituksesta päätetään yhtiökokouksessa erikseen.",
        15,
        95
      );
      doc.setFontSize(12);
      doc.setFont("bold");
      doc.text(
        "Kunnossapitotarveselvitys perustuu seuraaviin tutkimuksiin:",
        15,
        118
      );

      // Add the new table for selected examinations
      const selectedExaminationsData = examinations.filter((exam) =>
        selectedExaminations.includes(exam.id)
      );

      const examinationsData = selectedExaminationsData
        .map((exam) => {
          return Object.values({
            description: exam.description,
            year: exam.year,
          });
        })
        .sort((a, b) => a[0].localeCompare(b[0]));

      const examinationsHeaders = ["Tutkimuksen kuvaus", "Vuosi"];

      doc.autoTable({
        head: [examinationsHeaders],
        body: examinationsData,
        startY: 120,
        headStyles: {
          fillColor: ["#E1D1C2"],
          textColor: "#000000",
          fontStyle: "normal",
        },
      });

      const selectedData = rows
        .filter((row) => selectedRows.includes(row.elementId))
        .map((row) => ({
          ...row,
          ...localChanges[row.elementId],
        }));

      const kptsData = selectedData
        .map((row) => {
          return Object.values({
            element: row.element,
            action: row.action,
            plannedYear: row.plannedYear,
            yearsLeft: row.yearsLeft,
          });
        })
        .sort((a, b) => a[0].localeCompare(b[0]));

      const kptsHeaders = columns.slice(1).map((column) => column.label); // Exclude the 'select' column
      let tableStartY = doc.autoTable.previous.finalY + 10;

      doc.text(
        "Kunnossapitotarve koskee seuraavia elementtejä:",
        15,
        tableStartY - 2
      );

      doc.autoTable({
        head: [kptsHeaders],
        body: kptsData,
        startY: tableStartY,
        headStyles: {
          fillColor: ["#E1D1C2"],
          textColor: "#000000",
          fontStyle: "normal",
        },
      });

      // Add the new table for additional info
      const infoTableData = additionalInfo.map((info) => [info]);
      const infoTableHeaders = ["Lisätieto"];
      let infoTableStartY = doc.autoTable.previous.finalY + 10;

      doc.text("Lisätietoja:", 15, infoTableStartY - 2);
      doc.autoTable({
        head: [infoTableHeaders],
        body: infoTableData,
        startY: infoTableStartY,
        headStyles: {
          fillColor: ["#E1D1C2"],
          textColor: "#000000",
          fontStyle: "normal",
        },
      });
      doc.save(`${asoyDetails.name}_kpts${formData.year}.pdf`);
    };
  };

  return (
    <Box sx={{ display: "flex", marginLeft: isSmallScreen ? "-60px" : "0px" }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            onClose={handleCloseSnackbar}
            severity="info"
            sx={{ bgcolor: "#E1D1C2", color: "black" }}
          >
            PDF:ää muodostetaan! Odota hetki.
          </MuiAlert>
        </Snackbar>
        <CustomTable
          columns={columns}
          rows={rows.map((row) => ({
            ...row,
            ...localChanges[row.elementId],
            select: "", // Add a placeholder for the select column
          }))}
          onRowClick={handleRowClick}
          hoverBackgroundColor={"#f5f5f5"}
        />
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          fullWidth
        >
          <DialogTitle>Lisää toimenpide</DialogTitle>
          <DialogContent>
            <Box mb={2} mt={2}>
              <TextField
                label="Elementin nimi"
                value={selectedRow?.element}
                variant="standard"
                disabled
                fullWidth
              />
            </Box>
            <Box mb={2}>
              <InputLabel required id="action">
                Toimenpide
              </InputLabel>{" "}
              <Select
                value={selectedRow?.action}
                error={!!actionError}
                variant="standard"
                fullWidth
                onChange={(event) =>
                  setSelectedRow({ ...selectedRow, action: event.target.value })
                }
              >
                <MenuItem value="Hankkeen aloitus">Hankkeen aloitus</MenuItem>
                <MenuItem value="Tutkimuksen aloitus">
                  Tutkimuksen aloitus
                </MenuItem>
                <MenuItem value="Korjauksen aloitus">
                  Korjauksen aloitus
                </MenuItem>
              </Select>
              {actionError && (
                <FormHelperText error>{actionError}</FormHelperText>
              )}
            </Box>
            <Box mb={2}>
              <InputLabel required id="plannedYear-label">
                Suunniteltu vuosi
              </InputLabel>{" "}
              <Select
                value={selectedRow?.plannedYear}
                onChange={(event) =>
                  setSelectedRow({
                    ...selectedRow,
                    plannedYear: event.target.value,
                  })
                }
                displayEmpty
                fullWidth
                variant="standard"
                error={!!yearError}
              >
                {Array.from(
                  new Array(50),
                  (val, index) => new Date().getFullYear() + index
                ).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {yearError && <FormHelperText error>{yearError}</FormHelperText>}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Peruuta</Button>
            <Button onClick={handleDelete} color="secondary">
              Tyhjennä
            </Button>
            <Button onClick={handleDialogClose}>Tallenna</Button>
          </DialogActions>
        </Dialog>
        <Button variant="contained" onClick={handleKPTSOpen} sx={{ mt: 2 }}>
          Muodosta PDF
        </Button>
        <Dialog open={openKPTSDialog} onClose={handleCloseKPTSDialog} fullWidth>
          <DialogTitle>Lisää tutkimukset ja lisätiedot</DialogTitle>
          <DialogContent>
            <Box mb={2} mt={2}>
              <Autocomplete
                multiple
                options={examinations}
                getOptionLabel={(option) =>
                  `${option.description} - ${option.year}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Tutkimuksen kuvaus ja vuosi"
                  />
                )}
                value={
                  examinations.filter((exam) =>
                    selectedExaminations.includes(exam.id)
                  ) || []
                }
                onChange={(event, newValues) => {
                  setSelectedExaminations(
                    newValues ? newValues.map((value) => value.id) : []
                  );
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {`${option.description} - ${option.year}`}
                  </li>
                )}
              />
            </Box>
            <Box mb={2} display="flex" alignItems="center">
              <TextField
                multiline
                fullWidth
                margin="dense"
                label="Kirjaa lisätietoja"
                type="text"
                variant="standard"
                value={formData.info || ""}
                onChange={(e) =>
                  setFormData({ ...formData, info: e.target.value })
                }
              />
              <Button
                variant="contained"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setAdditionalInfo([...additionalInfo, formData.info]);
                  setFormData({ ...formData, info: "" });
                }}
              >
                Lisää
              </Button>
            </Box>

            {additionalInfo.map((info, index) => (
              <Box key={index} display="flex" alignItems="center">
                <p>{info}</p>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    const newAdditionalInfo = [...additionalInfo];
                    newAdditionalInfo.splice(index, 1);
                    setAdditionalInfo(newAdditionalInfo);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseKPTSDialog} color="primary">
              Peruuta
            </Button>
            <Button onClick={generatePdf} color="primary">
              Lataa PDF
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default ElementsTable;
