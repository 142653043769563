import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { ReactComponent as Logo } from "../icons/kontu.svg";
import theme from "../styles/theme.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/Authentication.js";
import axios from "axios";
import { useLocation } from "react-router-dom";
const backendUrl = process.env.REACT_APP_API_URL;


export default function SignIn() {
  const [errorMessage, setErrorMessage] = useState("");
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();

  async function validateForm(username, password) {
    if (username === "" || password === "") {
      setErrorMessage("Täytä kaikki kentät");
      return null;
    }
    try {
      const formData = new URLSearchParams();
      formData.append("username", username);
      formData.append("password", password);
      
      const response = await axios.post(`${backendUrl}/token/`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      
      console.log('Response received:', response);
      return response.data.access_token;
    } catch (error) {
      console.error("Error validating form: ", error);
      if (error.response) {
        setErrorMessage("Authentication failed. Please check your credentials.");
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
      return null;
    }
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const access_token = await validateForm(username, password);
    if (access_token) {
      setToken(access_token); // Save token in state
      localStorage.setItem('token', access_token); // Optionally save to localStorage
      const { state } = location;
      const redirectPath = state ? state.from.pathname : "/";
      navigate(redirectPath, { replace: true });
    } else {
      setErrorMessage("Authentication failed. Please check your credentials.");
    }
  };
  

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Logo
            style={{ maxHeight: "68px", width: "auto", margin: "10 auto" }}
          />
          <Typography component="h1" variant="h5">
            Kirjaudu sisään
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="kayttajatunnus"
              label="Käyttäjätunnus"
              name="kayttajatunnus"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="salasana"
              label="Salasana"
              type="password"
              id="salasana"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Kirjaudu sisään
            </Button>
            {errorMessage && (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
