import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import MainLayout from "../MainLayout";
import CustomTable from "../styles/CustomTable";
import CreateTeam from "../dialogs/CreateTeam";
import { useCache } from "../CacheProvider";

const backendUrl = process.env.REACT_APP_API_URL;


const TeamManagement = () => {
  const [Teams, setTeams] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({});
  const [createTeamsOpen, setCreateTeamsOpen] = useState(false);
  const [managers, setManagers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [buildingManagers, setBuildingManagers] = useState([]);
  const { get, set, remove } = useCache();

  const fetchTeams = useCallback(async () => {
    const cachedData = get("teams");
    if (cachedData) {
      setTeams(cachedData);
      return;
    }
    try {
      const response = await axios.get(`${backendUrl}/teams/`);
      setTeams(response.data);
      set("teams", response.data); // Store data in cache
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  }, [get, set]);

  useEffect(() => {
    fetchTeams();
    fetchManagers();
    fetchAreas();
    fetchBuildingManagers();
  }, [fetchTeams]);

  const fetchManagers = async () => {
    try {
      const response = await axios.get(`${backendUrl}/building_manager/`);
      setManagers(response.data);
      console.log("Fetched managers:", response.data); // Debugging log
    } catch (error) {
      console.error("Error fetching managers:", error);
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await axios.get(`${backendUrl}/area_lists/`);
      setAreas(response.data);
      console.log("Fetched areas:", response.data); // Debugging log
    } catch (error) {
      console.error("Error fetching areas:", error);
    }
  };

  const fetchBuildingManagers = async () => {
    try {
      const response = await axios.get(`${backendUrl}/building_manager/`);
      setBuildingManagers(response.data);
      console.log("Fetched building managers:", response.data); // Debugging log
    } catch (error) {
      console.error("Error fetching building managers:", error);
    }
  };

  const refreshTeams = () => {
    remove("teams"); // Force fetch new data
    fetchTeams();
  };

  const handleEditOpen = (team) => {
    setCurrentEdit({
      ...team,
      manager: team.manager || null,
      area_list: team.area_list || null,
      members: team.members || [],
    });
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setCurrentEdit((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMembersChange = (event, newValue) => {
    setCurrentEdit((prev) => ({
      ...prev,
      members: newValue,
    }));
  };

  const handleEditSave = async () => {
    try {
      const updatedTeam = {
        name: currentEdit.name,
        area_list_id: currentEdit.area_list ? currentEdit.area_list.id : null,
        manager_id: currentEdit.manager ? currentEdit.manager.id : null,
        building_manager_ids: currentEdit.members.map((bm) => bm.id),
      };
  
      await axios.put(`${backendUrl}/teams/${currentEdit.id}/updates/`, updatedTeam);
      refreshTeams();
      handleEditClose();
    } catch (error) {
      console.error("Error updating team:", error);
    }
  };
  

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${backendUrl}/teams/${id}/`);
      if (response.status === 204) {
        console.log("Team deleted successfully");
        refreshTeams(); // Refresh the data after deletion
        setEditOpen(false); // Close the dialog if it's open
      }
    } catch (error) {
      console.error("Error deleting team:", error);
    }
  };

  const columns = [
    { id: "name", label: "Tiimi" },
    { id: "area", label: "Alue", render: (row) => row.area_list?.name || "N/A" },
    { id: "manager", label: "Manageri", render: (row) => row.manager ? `${row.manager.first_name} ${row.manager.last_name}` : "N/A" },
    {
      id: "members",
      label: "Isännöitsijät",
      render: (row) => {
        const members = row.members || [];
        return members.length > 0
          ? members.map((member) => `${member.first_name} ${member.last_name}`).join(", ")
          : "Ei isännöitsijöitä";
      },
    },
  ];

  return (
    <MainLayout>
      <h1>Tiimien hallinta</h1>
      <Button variant="contained" onClick={() => setCreateTeamsOpen(true)} sx={{ mb: 2 }}>
        Lisää Tiimi
      </Button>
      <CreateTeam
        open={createTeamsOpen}
        onClose={() => {
          setCreateTeamsOpen(false);
          refreshTeams();
        }}
      />
      <CustomTable columns={columns} rows={Teams} onRowClick={handleEditOpen} />
      {editOpen && currentEdit ? (
        <Dialog open={editOpen} onClose={handleEditClose}>
          <DialogTitle>Muokkaa Tiimiä</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <TextField
                required
                autoFocus
                margin="dense"
                label="Tiimin Nimi"
                type="text"
                fullWidth
                variant="standard"
                name="name"
                value={currentEdit.name || ""}
                onChange={handleEditChange}
              />
            </Box>
            <Box mb={2}>
              <Autocomplete
                options={areas}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Alue"
                    variant="standard"
                  />
                )}
                value={currentEdit.area_list || null}
                onChange={(event, newValue) => {
                  setCurrentEdit((prev) => ({
                    ...prev,
                    area_list: newValue,
                  }));
                }}
              />
            </Box>
            <Box mb={2}>
              <Autocomplete
                options={managers}
                getOptionLabel={(option) => option ? `${option.first_name} ${option.last_name}` : ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Manageri"
                    variant="standard"
                  />
                )}
                value={currentEdit.manager || null}
                onChange={(event, newValue) => {
                  setCurrentEdit((prev) => ({
                    ...prev,
                    manager: newValue,
                  }));
                }}
              />
            </Box>
            <Box mb={2}>
              <Autocomplete
                multiple
                options={buildingManagers}
                getOptionLabel={(option) => option ? `${option.first_name} ${option.last_name}` : ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Isännöitsijät"
                    variant="standard"
                  />
                )}
                value={currentEdit.members || []}
                onChange={handleMembersChange}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>Peruuta</Button>
            <Button onClick={handleEditSave}>Tallenna</Button>
            <Button onClick={() => handleDelete(currentEdit.id)} color="error">
              Poista
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </MainLayout>
  );
};

export default TeamManagement;
