import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  RadioGroup,
  Radio,
  Autocomplete,
  FormControlLabel,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { PieChart } from "@mui/x-charts";
import debounce from "lodash.debounce";
import WarningIcon from "@mui/icons-material/Warning";
import { useCache } from "../CacheProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const backendUrl = process.env.REACT_APP_API_URL;

const WarningTriangle = ({ lastRepaired, maint_interval }) => {
  const nextInspectionYear = lastRepaired + (maint_interval || 0);
  if (nextInspectionYear < 1) {
    return (
      <Tooltip title="Huolto tänä vuonna">
        <WarningIcon color="error" />
      </Tooltip>
    );
  }
};

const TimelineProgress = ({ name, lastRepaired, lifespan, maint_interval }) => {
  const currentYear = new Date().getFullYear();
  const lastRepairedYear = lastRepaired
    ? new Date(lastRepaired).getFullYear()
    : null;
  const elapsed = lastRepairedYear ? currentYear - lastRepairedYear : 0;
  const percentComplete = lifespan > 0 ? (elapsed / lifespan) * 100 : 0;
  const currentIndicatorPosition = Math.min(100, percentComplete);

  if (lifespan === null) {
    return (
      <Box sx={{ mb: 4, position: "relative", width: "100%" }}>
        <Typography component="div" variant="subtitle1" gutterBottom>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {name}
            <WarningTriangle
              lastRepaired={lastRepaired}
              maint_interval={maint_interval}
            />
          </Box>
        </Typography>
        <Typography
          component="div"
          variant="subtitle1"
          sx={{ fontSize: "1rem" }}
        >
          Elementin elinkaari kestää koko rakennuksen käyttöiän
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 4, position: "relative", width: "100%" }}>
      <Typography component="div" variant="subtitle1" gutterBottom>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {name}
          <WarningTriangle
            lastRepaired={lastRepaired}
            maint_interval={maint_interval}
          />
        </Box>
      </Typography>
      <Box
        sx={{
          height: 20,
          borderRadius: 5,
          width: "100%",
          background: `
          linear-gradient(
            to right, 
            #A9F4D0 0%, #A9F4D0 70%, /* Green to the 70% mark */
            #FBE38E 70%, #FBE38E 80%, /* Yellow starts immediately at 70%, goes to 85% */
            #FDD09F 80%, #FDD09F 90%, /* Orange starts immediately at 85%, goes to 90% */
            #FEAEAE 90%, #FEAEAE 100% /* Red starts immediately at 90% */
          )`,
          position: "relative",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: `${percentComplete}%`,
            backgroundColor: "rgba(255, 255, 255, 0.2)", // Reduced opacity for a brighter appearance
            borderRadius: "inherit",
          }}
        />
        {lifespan && (
          <>
            {/* Current Situation Indicator */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: `${currentIndicatorPosition}%`,
                height: "100%",
                width: "2px", // Width of the indicator line
                backgroundColor: "#000", // Color of the indicator line
              }}
            />
            {/* Text showing the current situation (elapsed/lifespan) */}
            <Typography
              sx={{
                position: "absolute",
                top: "100%", // Position below the progress bar
                left: `${currentIndicatorPosition}%`,
                transform: "translateX(-50%)", // Center align the text with the indicator
                fontSize: "0.75rem",
                mt: "2px", // Margin top to space out the text from the progress bar
              }}
            >
              {`${elapsed}/${lifespan}`}
            </Typography>
          </>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "right", mt: 1 }}>
        <Typography
          component="div"
          variant="subtitle1"
          sx={{ fontSize: "1rem" }}
        >
          {lifespan ? `${lifespan}` : "N/A"}
        </Typography>
      </Box>
    </Box>
  );
};

const StructuralElements = () => {
  const { asoyId } = useParams();
  const [structuralElements, setStructuralElements] = useState([]);
  const [asoyName, setAsoyName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [editStructOpen, setEditStructOpen] = useState(false);
  const [pieChartData, setPieChartData] = useState([]);
  const [currentElementEdit, setCurrentElementEdit] = useState({
    id: "",
    name: "",
    lastRepaired: "",
    lifespan: "",
  });
  const [currentStructuralElementEdit, setCurrentStructuralElementEdit] =
    useState({
      id: "",
      name: "",
    });
  const [scrollPosition, setScrollPosition] = useState(0); // State to store scroll position
  const [createElementOpen, setCreateElementOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("rakenneosa");
  const { get, set, remove } = useCache();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const chartOuterRadius = isSmallScreen ? 70 : 100;
  const chartInnerRadius = isSmallScreen ? 35 : 50;
  const [formData, setFormData] = useState({
    structElem: "",
    elem: "",
  });
  const [fieldErrors, setFieldErrors] = useState({
    structuralElement: "",
    element: "",
    modElem: "",
  });

  // Sorting function
  const sortStructuralElements = (structuralElements) => {
    return structuralElements
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((structuralElement) => ({
        ...structuralElement,
        elements: structuralElement.elements
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name)),
      }));
  };

  const handleElementEditOpen = (element) => {
    console.log("Element to edit:", element); // Log to ensure data integrity
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);

    setCurrentElementEdit({
      id: element.id,
      name: element.name,
      lastRepaired: element.last_repaired || "", // Handle undefined
      lifespan: element.lifespan || "", // Handle undefined
    });

    setFieldErrors({});
    setEditOpen(true);
  };

  const handleStructuralElementEditOpen = (structuralElement) => {
    // Store the current scroll position
    const currentScrollPosition =
      window.scrollY || document.documentElement.scrollTop;
    setScrollPosition(currentScrollPosition); // Store it

    setCurrentStructuralElementEdit({
      id: structuralElement.id,
      name: structuralElement.name,
    });

    setFieldErrors({});
    setEditStructOpen(true);
  };

  const handleEditClose = () => {
    setFormData({
      modElem: "",
      year: "",
      lifespan: "",
    });

    setEditOpen(false);

    // Delay scroll restoration slightly to ensure it happens after the dialog has fully closed
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 150); // Adjust the delay as needed
  };

  const handleEditStructClose = () => {
    setEditStructOpen(false);

    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 150); // Adjust the delay as needed
  };

  const handlecreateElementOpen = () => {
    setCreateElementOpen(true);
  };
  const handlecreateElementClose = () => {
    setCreateElementOpen(false);
    refreshStructuralElements();
    setFormData({
      structElem: "",
      elem: "",
    });
    resetFieldErrors();
  };

  const resetFieldErrors = () => {
    setFieldErrors({
      structuralElement: "",
      element: "",
    });
  };

  const handleCreateElementSave = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    try {
      if (selectedOption === "rakenneosa") {
        await createStructuralElement();
      } else if (selectedOption === "elementti") {
        await createElement();
      }

      resetForm();
      closeDialog();
      refreshStructuralElements();
    } catch (error) {
      handleSaveError(error);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.structElem) errors.structuralElement = "Kenttä vaaditaan";
    if (!formData.elem && selectedOption === "elementti")
      errors.element = "Kenttä vaaditaan";
    return errors;
  };

  const createStructuralElement = async () => {
    const response = await axios.post(`${backendUrl}/structural_elements/`, {
      name: formData.structElem,
      asoy_id: parseInt(asoyId, 10), // Ensure asoyId is sent as a number
    });
    console.log("New structural element created:", response.data);
  };

  const createElement = async () => {
    const structuralElement = structuralElements.find(
      (se) => se.name === formData.structElem
    );
    if (!structuralElement) {
      throw new Error("Rakenneosaa ei löydy");
    }

    const response = await axios.post(`${backendUrl}/elements/`, {
      name: formData.elem,
      structural_element_id: parseInt(structuralElement.id, 10), // Ensure structuralElement.id is sent as a number
    });
    console.log("New element created:", response.data);
  };

  const resetForm = () => {
    setFormData({
      structElem: "",
      elem: "",
    });
  };

  const closeDialog = () => {
    setCreateElementOpen(false);
  };

  const handleSaveError = (error) => {
    console.error("Error creating new element:", error);
    if (error.message === "Rakenneosaa ei löydy") {
      setFieldErrors({ structuralElement: error.message });
    } else {
      setFieldErrors({ general: "Virhe luotaessa uutta elementtiä" });
    }
    resetFieldErrors(); // Clears errors after displaying them
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setFormData({
      structElem: "",
      elem: "",
    });
    resetFieldErrors();
  };

  const fetchStageData = useCallback(async () => {
    let elements = get(`asoy_${asoyId}_elements`);
    if (!elements) {
      try {
        const response = await axios.get(
          `${backendUrl}/asoy/${asoyId}/elements/`
        );
        console.log("Fetched elements from API:", response.data); // Log the API response
        elements = response.data;
        set(`asoy_${asoyId}_elements`, elements); // Store data in cache
      } catch (error) {
        console.error("Error fetching element data:", error);
        return;
      }
    } else {
      console.log("Fetched elements from cache:", elements); // Log if fetched from cache
    }

    const stages = {
      all_good: { count: 0, color: "#A9F4D0", name: "Kaikki hyvin" },
      inspect: { count: 0, color: "#FBE38E", name: "Tutki" },
      start_project: { count: 0, color: "#FDD09F", name: "Hankkeen aloitus" },
      lifespan_ending: {
        count: 0,
        color: "#FEAEAE",
        name: "Elinkaari lopussa",
      },
    };

    elements.forEach((element) => {
      // Skip elements with NULL or zero lifespans
      if (!element.lifespan || element.lifespan === 0) {
        return;
      }

      // Assuming last_repaired is handled in the backend and always has a value
      const lastRepairedYear = element.last_repaired
        ? new Date(element.last_repaired).getFullYear()
        : new Date().getFullYear();
      const currentYear = new Date().getFullYear();
      const lifespanCompleted =
        ((currentYear - lastRepairedYear) / element.lifespan) * 100;

      if (lifespanCompleted < 70) {
        stages.all_good.count += 1;
      } else if (lifespanCompleted >= 70 && lifespanCompleted < 80) {
        stages.inspect.count += 1;
      } else if (lifespanCompleted >= 80 && lifespanCompleted < 90) {
        stages.start_project.count += 1;
      } else if (lifespanCompleted >= 90) {
        stages.lifespan_ending.count += 1;
      }
    });

    const processedData = Object.values(stages).map((stage, index) => ({
      id: index,
      value: stage.count,
      color: stage.color,
      label: stage.name,
    }));

    setPieChartData(processedData);
  }, [asoyId, get, set]);

  const fetchStructuralElements = useCallback(async () => {
    // Try to get data from cache
    const cachedData = get(`asoy_${asoyId}`);
    if (cachedData) {
      const sortedStructuralElements = sortStructuralElements(
        cachedData.structural_elements
      );
      setStructuralElements(sortedStructuralElements);
      setAsoyName(cachedData.name);
      return;
    }
    try {
      const response = await axios.get(`${backendUrl}/asoy/${asoyId}/`);
      const sortedStructuralElements = sortStructuralElements(
        response.data.structural_elements
      );
      setStructuralElements(sortedStructuralElements);
      setAsoyName(response.data.name);
      // Update the cache with sorted data
      set(`asoy_${asoyId}`, {
        ...response.data,
        structural_elements: sortedStructuralElements,
      });
    } catch (error) {
      console.error("Error fetching structural elements", error);
    }
  }, [asoyId, get, set]); // asoyId is a dependency of fetchStructuralElements

  useEffect(() => {
    fetchStructuralElements();
    fetchStageData();
  }, [fetchStructuralElements, fetchStageData, asoyId]);

  const refreshStructuralElements = () => {
    remove(`asoy_${asoyId}`); // Force fetch new data
    fetchStructuralElements();
  };

  const handleEditSave = async () => {
    const { name, lastRepaired, lifespan } = currentElementEdit;
    const errors = {};

    if (!name) errors.modElem = "Kenttä vaaditaan";
    if (!lastRepaired) errors.year = "Kenttä vaaditaan";
    if (!lifespan) errors.lifespan = "Kenttä vaaditaan";

    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    setFormData({
      modElem: "",
      year: "",
      lifespan: "",
    });

    const payload = {
      name,
      lifespan,
      last_repaired: lastRepaired ? `${lastRepaired}-01-01` : null,
    };

    console.log("Saving element with payload:", payload); // Add logging for payload

    try {
      await axios.put(
        `${backendUrl}/elements/${currentElementEdit.id}/update-details/`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      refreshStructuralElements(); // Refresh after successful update
      setEditOpen(false);
    } catch (error) {
      console.error("Error updating element details:", error);
    }
  };

  const handleEditDelete = async () => {
    try {
      await axios.delete(`${backendUrl}/elements/${currentElementEdit.id}/`);
      refreshStructuralElements();
      setEditOpen(false);
    } catch (error) {
      console.error("Error deleting element:", error);
    }
  };

// Function to update structural element
const handleEditStructSave = async () => {
  const { name } = currentStructuralElementEdit;
  if (!name) {
    setFieldErrors({ structElem: "Kenttä vaaditaan" });
    return;
  }

  const payload = { name };

  try {
    await axios.put(
      `${backendUrl}/structural_elements/${currentStructuralElementEdit.id}/update-details/`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    refreshStructuralElements();
    setEditStructOpen(false);
  } catch (error) {
    console.error("Error updating structural element details:", error);
  }
};

// Function to delete structural element
const handleEditStructDelete = async () => {
  try {
    await axios.delete(
      `${backendUrl}/structural_elements/${currentStructuralElementEdit.id}/`
    );
    refreshStructuralElements();
    setEditStructOpen(false);
  } catch (error) {
    console.error("Error deleting structural element:", error);
  }
};


  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setSearchQuery(value);
      }, 300),
    []
  );

  const handleSearchChange = (event) => {
    debouncedSearch(event.target.value.toLowerCase());
  };

  const filteredData = useMemo(() => {
    if (!structuralElements) return []; // Return an empty array if structuralElements is undefined
    return structuralElements.filter((structuralElement) => {
      return (
        structuralElement.name.toLowerCase().includes(searchQuery) ||
        structuralElement.elements.some((element) =>
          element.name.toLowerCase().includes(searchQuery)
        )
      );
    });
  }, [structuralElements, searchQuery]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
      <Box sx={{ width: "100%" }}>
        <Typography component="div" variant="h6" textAlign="center">
          Rakenneosat ja elementit taloyhtiölle: {asoyName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PieChart
            series={[
              {
                data: pieChartData,
                innerRadius: 50,
                outerRadius: 100,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: 0,
                endAngle: 360,
                cx: isSmallScreen ? 300 : 200,
              },
            ]}
            width={800}
            height={300}
            slotProps={{
              legend: {
                display: true,
                direction: "column", // or 'column'
                position: {
                  vertical: "middle", // or 'middle', 'bottom'
                  horizontal: "right", // or 'left', 'right'
                },
                padding: isSmallScreen ? { right: "210" } : { right: "180" }, // or { top: 0, bottom: 0, left: 0, right: 0 }
              },
            }}
          />
        </Box>
        <TextField
          label="Hae rakenneosaa tai elementtiä"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={handleSearchChange}
        />
        <Button
          variant="contained"
          sx={{ mb: 2 }}
          onClick={handlecreateElementOpen}
        >
          Lisää uusi rakenneosa / elementti
        </Button>
        <Dialog open={createElementOpen} onClose={handlecreateElementClose}>
          <DialogTitle>Lisää uusi rakenneosa / elementti</DialogTitle>
          <DialogContent>
            <RadioGroup
              aria-label="Rakenneosa tai Elementti"
              name="rakenneosa-elementti"
              value={selectedOption}
              onChange={handleOptionChange}
              sx={{ mb: 2 }}
            >
              <FormControlLabel
                value="rakenneosa"
                control={<Radio />}
                label="Rakenneosa"
              />
              <FormControlLabel
                value="elementti"
                control={<Radio />}
                label="Elementti"
              />
            </RadioGroup>

            {selectedOption === "rakenneosa" ? (
              <TextField
                required
                autoFocus
                margin="dense"
                id="name"
                label="Rakenneosa"
                type="text"
                fullWidth
                variant="standard"
                sx={{ mb: 2 }}
                onChange={(e) =>
                  setFormData({ ...formData, structElem: e.target.value })
                }
                error={Boolean(fieldErrors.structuralElement)}
                helperText={fieldErrors.structuralElement}
              />
            ) : (
              <>
                <Autocomplete
                  id="rakenneosa-autocomplete"
                  sx={{ mb: 2 }}
                  options={filteredData.map(
                    (structuralElement) => structuralElement.name
                  )}
                  getOptionLabel={(option) => option}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      structElem: e.target.textContent,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Rakenneosa"
                      variant="standard"
                      error={Boolean(fieldErrors.structuralElement)}
                      helperText={fieldErrors.structuralElement}
                    />
                  )}
                />
                <TextField
                  required
                  id="element"
                  label="Elementti"
                  fullWidth
                  variant="standard"
                  sx={{ mb: 2 }}
                  onChange={(e) =>
                    setFormData({ ...formData, elem: e.target.value })
                  }
                  error={Boolean(fieldErrors.element)}
                  helperText={fieldErrors.element}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handlecreateElementClose}>Peruuta</Button>
            <Button onClick={handleCreateElementSave}>Lisää uusi</Button>
          </DialogActions>
        </Dialog>

        <Grid container spacing={2} justifyContent="center">
          {filteredData.length ? (
            filteredData.map((structuralElement) => (
              <Grid item xs={12} key={structuralElement.id}>
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        mb: 2,
                        cursor: "pointer",
                        transition:
                          "background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out", // Smooth transition for background color and shadow
                        "&:hover": {
                          backgroundColor: (theme) =>
                            theme.palette.primary.main, // Use main color from the theme
                          boxShadow: "0 2px 4px rgba(0,0,0,0.2)", // Add a subtle shadow on hover
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleStructuralElementEditOpen(structuralElement);
                      }}
                    >
                      <Typography variant="h5" component="div">
                        {structuralElement.name}
                      </Typography>
                    </Box>
                    {structuralElement.elements.map((element) => (
                      <Box key={element.id}>
                        <Box
                          sx={{
                            mb: 2,
                            cursor: "pointer",
                            transition:
                              "background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out", // Smooth transition for background color and shadow
                            "&:hover": {
                              backgroundColor: (theme) =>
                                theme.palette.primary.main, // Use main color from the theme
                              boxShadow: "0 2px 4px rgba(0,0,0,0.2)", // Add a subtle shadow on hover
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent event from propagating to the card
                            handleElementEditOpen(element); // Open the edit dialog for this element
                          }}
                        >
                          <TimelineProgress
                            name={element.name}
                            lastRepaired={element.last_repaired}
                            lifespan={element.lifespan}
                            maint_interval={element.maint_interval}
                          />
                        </Box>
                      </Box>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography>No structural elements found.</Typography>
          )}
        </Grid>

        {/* Edit Structural Element Dialog */}
        <Dialog open={editStructOpen} onClose={handleEditStructClose}>
          <DialogTitle>Muokkaa rakenneosaa</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="structuralElementName"
              label="Rakenneosan nimi"
              type="text"
              fullWidth
              variant="standard"
              value={currentStructuralElementEdit.name || ""}
              onChange={(e) => {
                setCurrentStructuralElementEdit({
                  ...currentStructuralElementEdit,
                  name: e.target.value,
                });
              }}
              error={Boolean(fieldErrors.structElem)}
              helperText={fieldErrors.structElem}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditStructClose}>Peruuta</Button>
            <Button onClick={handleEditStructSave}>Tallenna</Button>
            <Button onClick={handleEditStructDelete} color="error">
              Poista
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Element Dialog */}
        <Dialog open={editOpen} onClose={handleEditClose}>
          <DialogTitle>Muokkaa elementin tietoja</DialogTitle>
          <DialogContent>
            <Box mb={3}>
              <InputLabel required id="element-label">
                Elementti
              </InputLabel>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                variant="standard"
                value={currentElementEdit.name || ""}
                onChange={(e) => {
                  setCurrentElementEdit({
                    ...currentElementEdit,
                    name: e.target.value,
                  });
                }}
                error={Boolean(fieldErrors.modElem)}
                helperText={fieldErrors.modElem}
              />
            </Box>

            <Box mb={3}>
              <InputLabel required id="last-repair-label">
                Viimeksi korjattu
              </InputLabel>
              <Select
                value={currentElementEdit.lastRepaired || ""} // Set a default value if undefined
                onChange={(e) => {
                  const year = e.target.value;
                  setCurrentElementEdit({
                    ...currentElementEdit,
                    lastRepaired: year,
                  });
                }}
                displayEmpty
                fullWidth
                variant="standard"
                error={Boolean(fieldErrors.year)}
                helperText={fieldErrors.year}
              >
                {Array.from(
                  new Array(100),
                  (val, index) => new Date().getFullYear() - index
                ).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box mb={3}>
              <InputLabel required id="lifespan-label">
                Elinkaari (vuosia)
              </InputLabel>
              <Select
                value={currentElementEdit.lifespan || ""} // Set a default value if undefined
                onChange={(e) => {
                  setCurrentElementEdit({
                    ...currentElementEdit,
                    lifespan: e.target.value,
                  });
                }}
                displayEmpty
                fullWidth
                variant="standard"
                error={Boolean(fieldErrors.lifespan)}
                helperText={fieldErrors.lifespan}
              >
                {Array.from(new Array(100), (val, index) => 1 + index).map(
                  (year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  )
                )}
              </Select>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleEditClose}>Peruuta</Button>
            <Button onClick={handleEditSave}>Tallenna</Button>
            <Button onClick={handleEditDelete} color="error">
              Poista
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default StructuralElements;
