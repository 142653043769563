import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import theme from "../styles/theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";

const CustomPieChart = ({ data, navigate }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const chartOuterRadius = isSmallScreen ? 70 : 100;
  const chartInnerRadius = isSmallScreen ? 35 : 50;
  const spacing = isSmallScreen ? 0 : 1; // Adjust spacing dynamically based on screen size

  // Define the click handler within the component to have access to `navigate`
  const handleSliceClick = (event, ItemId, item) => {
    // Assuming `data` is the dataset passed to the PieChart component and
    // each item in the dataset has an `id` property.
    if (item.id)
      navigate(`/raportit/${item.id}`);
    else
      console.error("No id found in slice data:", item);
  };

  const isChartDataValid = data && data.length > 0;

  const colors = [
    "#D0E8FF",
    "#DBAEFF",
    "#FBE38E",
    "#FED0EE",
    "#A9F4D0" /* ... other colors ... */,
  ];

  return isChartDataValid ? (
    <Box
      sx={{
        position: "relative",
        mt: spacing, // Adjust margin top dynamically based on screen size
        mb: spacing, // Adjust margin bottom dynamically based on screen size
      }}
      key={isSmallScreen ? "small" : "large"}
    >
      <PieChart
        series={[
          {
            data,
            innerRadius: chartInnerRadius,
            outerRadius: chartOuterRadius,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: 0,
            endAngle: 360,
            cx: isSmallScreen ? 70 : 130,
          },
        ]}
        width={isSmallScreen ? 395 : 800}
        height={300}
        onClick={handleSliceClick} // Directly use handleSliceClick here
        colors={colors}
        slotProps={{
          legend: {
            display: true,
            direction: 'column', // or 'column'
            position: {
              vertical: 'middle', // or 'middle', 'bottom'
              horizontal: 'right' // or 'left', 'right'
            },
            padding: isSmallScreen ? { right: '-150' } : { right: '150' }, // or { top: 0, bottom: 0, left: 0, right: 0 }
          },
        }}
      />
    </Box>
  ) : null;
};

export default CustomPieChart;
