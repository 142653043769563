import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Box,
  CssBaseline,
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  AppBar,
  IconButton,
  Typography,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { ReactComponent as CustomHomeIcon } from "../icons/house.svg";
import { ReactComponent as CustomReportIcon } from "../icons/report.svg";
import { ReactComponent as Logo } from "../icons/kontu.svg";
import { ReactComponent as CustomUserManagementIcon } from "../icons/users.svg";
import { ReactComponent as CustomTeamManagementIcon } from "../icons/team.svg";
import { ReactComponent as CustomBuildingManagerIcon } from "../icons/building-manager.svg";
import { ReactComponent as CustomAsOyManagementIcon } from "../icons/city.svg";
import { ReactComponent as ManagementIcon } from "../icons/management.svg";
import AccountMenu from "./AccountMenu";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { useAuth } from "../auth/Authentication";

// Adjust the drawerWidth to match your design
const drawerWidth = 240;

function SideNavbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const navigate = useNavigate();
  const [managementOpen, setManagementOpen] = React.useState(false);
  const { decodedToken } = useAuth();
  const location = useLocation();
  const [selectedPage, setSelectedPage] = React.useState(location.pathname.split('/')[1]);


  const handleManagementClick = () => {
    setManagementOpen(!managementOpen);
  };

  // Functions to handle the events of the drawer
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  React.useEffect(() => {
    const currentPage = location.pathname.split('/')[1];
    setSelectedPage(currentPage);

    // Check if the current page is one of the management pages
    const managementPages = ["user-management", "building-manager-management", "team-management", "asoy-management"];
    if (managementPages.includes(currentPage)) {
      setManagementOpen(true);
    } else {
      setManagementOpen(false);
    }

  }, [location]);

  const drawer = (
    <div>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
        }}
      >
        <ListItemButton
          onClick={() => {
            navigate("/");
          }}
          sx={{
            justifyContent: "center",
            padding: 0,
            width: "100%",
          }}
        >
          <Logo style={{ maxHeight: "48px", width: "auto", margin: "0 auto" }} />
        </ListItemButton>
      </Toolbar>
      <ListItemButton
        onClick={() => {
          navigate("/");
        }}
        sx={{
          backgroundColor: selectedPage === "" ? "#E1D1C2" : "white",
        }}
      >
        <ListItemIcon>
          <CustomHomeIcon style={{ maxHeight: "40px", maxWidth: "40px" }} />
        </ListItemIcon>
        <ListItemText primary="Etusivu" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          navigate("/raportit");
        }}
        sx={{
          backgroundColor: selectedPage === "raportit" ? "#E1D1C2" : "white",
        }}
      >
        <ListItemIcon>
          <CustomReportIcon style={{ maxHeight: "40px", maxWidth: "40px" }} />
        </ListItemIcon>
        <ListItemText primary="Raportit" />
      </ListItemButton>

      {/* Management section with collapsible items */}
      {decodedToken && decodedToken.role === 1 && (
        <div>
          <ListItemButton onClick={handleManagementClick}>
            <ListItemIcon>
              <ManagementIcon style={{ maxHeight: "40px", maxWidth: "40px" }} />
            </ListItemIcon>
            <ListItemText primary="Hallinta" />
            {managementOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={managementOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate("/user-management")
                }}
                sx={{
                  pl: 4,
                  backgroundColor: selectedPage === "user-management" ? "#E1D1C2" : "white",
                }}
              >
                <ListItemIcon>
                  <CustomUserManagementIcon style={{ maxHeight: "30px", maxWidth: "30px" }} />
                </ListItemIcon>
                <ListItemText primary="Käyttäjät" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/building-manager-management")
                }}
                sx={{
                  pl: 4,
                  backgroundColor: selectedPage === "building-manager-management" ? "#E1D1C2" : "white",
                }}
              >
                <ListItemIcon>
                  <CustomBuildingManagerIcon style={{ maxHeight: "30px", maxWidth: "30px" }} />
                </ListItemIcon>
                <ListItemText primary="Isännöitsijät" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/team-management")
                }}
                sx={{
                  pl: 4,
                  backgroundColor: selectedPage === "team-management" ? "#E1D1C2" : "white",
                }}
              >
                <ListItemIcon>
                  <CustomTeamManagementIcon style={{ maxHeight: "30px", maxWidth: "30px" }} />
                </ListItemIcon>
                <ListItemText primary="Tiimit" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  navigate("/asoy-management")
                }}
                sx={{
                  pl: 4,
                  backgroundColor: selectedPage === "asoy-management" ? "#E1D1C2" : "white",
                }}
              >
                <ListItemIcon>
                  <CustomAsOyManagementIcon style={{ maxHeight: "30px", maxWidth: "30px" }} />
                </ListItemIcon>
                <ListItemText primary="Taloyhtiöt" />
              </ListItemButton>
            </List>
          </Collapse>
        </div>
      )}
    </div>
  );

  let container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          bgcolor: "background.default",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              TaloKunto
            </Typography>
          </Box>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 }, }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default SideNavbar;
