import React, { useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from "@mui/material";
import MainLayout from "../MainLayout";
import CustomTable from "../styles/CustomTable";
import CreateAsoy from "../dialogs/CreateAsoy";
import AsoyContext from "../AsoyContext";
import axios from "axios";
const backendUrl = process.env.REACT_APP_API_URL;

const AsoyManagement = () => {
  const { filteredData, refreshData } = useContext(AsoyContext); // Assuming refreshData is provided by the context
  const [createAsoyOpen, setCreateAsoyOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    business_id: "",
    address_road: "",
    address_number: "",
    address_postal_code: "",
    address_city: "",
    total_shares: "",
    total_sqr_m: "",
  });

  const handleEditOpen = (asoy) => {
    setCurrentEdit({
      ...asoy,
      address_road: asoy.address_road,
      address_number: asoy.address_number,
      address_postal_code: asoy.address_postal_code,
      address_city: asoy.address_city,
    });
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setFieldErrors({});
  };

  const handleEditSave = async () => {
    const errors = {};
    if (!currentEdit.name) errors.name = "Kenttä vaaditaan";
    if (!currentEdit.business_id) errors.business_id = "Kenttä vaaditaan";
    if (!currentEdit.address_road) errors.address_road = "Kenttä vaaditaan";
    if (!currentEdit.address_number) errors.address_number = "Kenttä vaaditaan";
    if (!currentEdit.address_postal_code)
      errors.address_postal_code = "Kenttä vaaditaan";
    if (!currentEdit.address_city) errors.address_city = "Kenttä vaaditaan";
    if (!currentEdit.total_shares) errors.total_shares = "Kenttä vaaditaan";
    if (!currentEdit.total_sqr_m) errors.total_sqr_m = "Kenttä vaaditaan";
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    if (
      fieldErrors.name ||
      fieldErrors.address_road ||
      fieldErrors.address_number ||
      fieldErrors.address_postal_code ||
      fieldErrors.address_city ||
      fieldErrors.total_shares ||
      fieldErrors.total_sqr_m
    ) {
      return;
    }
    try {
      const response = await axios.patch(
        `${backendUrl}/asoy/${currentEdit.id}/update-info/`,
        currentEdit
      );
      if (response.status === 200) {
        console.log("Asoy updated successfully");
        refreshData(); // Refresh the table data after save
        handleEditClose(); // Close the dialog after save
      }
    } catch (error) {
      console.error("Error updating Asoy:", error);
      // Handle error, potentially updating the UI to reflect the error state
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${backendUrl}/asoy/${id}/`);
      if (response.status === 204) {
        console.log("Asoy deleted successfully");
        refreshData(); // Refresh the table data after save
        setEditOpen(false); // Close the dialog if it's open
      }
    } catch (error) {
      console.error("Error deleting Asoy:", error);
      // Handle error, potentially updating the UI to reflect the error state
    }
    setFieldErrors({});
  };

  const columns = [
    { id: "name", label: "Taloyhtiö" },
    { id: "business_id", label: "Y-tunnus" },
    {
      id: "address",
      label: "Osoite",
      render: (row) => {
        if (
          row.address_road &&
          row.address_number &&
          row.address_postal_code &&
          row.address_city
        ) {
          return `${row.address_road} ${row.address_number}, ${row.address_postal_code} ${row.address_city}`;
        } else {
          return "";
        }
      },
    },
    { id: "total_shares", label: "Osakkeiden lkm" },
    { id: "total_sqr_m", label: "Neliöt" },
  ];

  // Sort the filteredData in reverse alphabetical order by name
  const sortedData = filteredData.sort((a, b) => b.name.localeCompare(a.name));

  return (
    <MainLayout>
      <h1>Taloyhtiöhallinta</h1>
      <Button
        variant="contained"
        onClick={() => setCreateAsoyOpen(true)}
        sx={{ mb: 2 }}
      >
        Lisää Taloyhtiö{" "}
      </Button>
      <CreateAsoy
        open={createAsoyOpen}
        onClose={() => {
          setCreateAsoyOpen(false);
          refreshData();
        }}
      />
      <CustomTable
        columns={columns}
        rows={sortedData}
        onRowClick={handleEditOpen}
      />
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Muokkaa taloyhtiötä</DialogTitle>
        <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            label="Nimi"
            type="text"
            fullWidth
            variant="standard"
            value={currentEdit.name || ""}
            onChange={(e) =>
              setCurrentEdit({ ...currentEdit, name: e.target.value })
            }
            error={Boolean(fieldErrors.name)}
            helperText={fieldErrors.name}
          />
          <TextField
            required
            margin="dense"
            label="Y-tunnus"
            type="text"
            fullWidth
            variant="standard"
            value={currentEdit.business_id || ""}
            onChange={(e) =>
              setCurrentEdit({ ...currentEdit, business_id: e.target.value })
            }
            error={Boolean(fieldErrors.business_id)}
            helperText={fieldErrors.business_id}
          />

          <Grid container>
            <Grid item xs={6}>
              <TextField
                required
                margin="dense"
                label="Tie"
                type="text"
                fullWidth
                variant="standard"
                value={currentEdit.address_road || ""}
                onChange={(e) =>
                  setCurrentEdit({
                    ...currentEdit,
                    address_road: e.target.value,
                  })
                }
                error={Boolean(fieldErrors.address_road)}
                helperText={fieldErrors.address_road}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                margin="dense"
                label="Numero"
                type="text"
                fullWidth
                variant="standard"
                value={currentEdit.address_number || ""}
                onChange={(e) =>
                  setCurrentEdit({
                    ...currentEdit,
                    address_number: e.target.value,
                  })
                }
                error={Boolean(fieldErrors.address_number)}
                helperText={fieldErrors.address_number}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                margin="dense"
                label="Postinumero"
                type="text"
                fullWidth
                variant="standard"
                value={currentEdit.address_postal_code || ""}
                onChange={(e) =>
                  setCurrentEdit({
                    ...currentEdit,
                    address_postal_code: e.target.value,
                  })
                }
                error={Boolean(fieldErrors.address_postal_code)}
                helperText={fieldErrors.address_postal_code}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                margin="dense"
                label="Kaupunki"
                type="text"
                fullWidth
                variant="standard"
                value={currentEdit.address_city || ""}
                onChange={(e) =>
                  setCurrentEdit({
                    ...currentEdit,
                    address_city: e.target.value,
                  })
                }
                error={Boolean(fieldErrors.address_city)}
                helperText={fieldErrors.address_city}
              />
            </Grid>
          </Grid>
          <TextField
            required
            margin="dense"
            label="Osakkeiden lkm"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            fullWidth
            variant="standard"
            value={currentEdit.total_shares || ""}
            onChange={(e) =>
              setCurrentEdit({ ...currentEdit, total_shares: e.target.value })
            }
            error={Boolean(fieldErrors.total_shares)}
            helperText={fieldErrors.total_shares}
          />
          <TextField
            required
            margin="dense"
            label="Neliöt"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            fullWidth
            variant="standard"
            value={currentEdit.total_sqr_m || ""}
            onChange={(e) =>
              setCurrentEdit({ ...currentEdit, total_sqr_m: e.target.value })
            }
            error={Boolean(fieldErrors.total_sqr_m)}
            helperText={fieldErrors.total_sqr_m}
          />
          {/* Add more fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Peruuta</Button>
          <Button onClick={handleEditSave}>Tallenna</Button>
          <Button onClick={() => handleDelete(currentEdit.id)} color="error">
            Poista
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
};

export default AsoyManagement;
