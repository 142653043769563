import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import AsoyContext from './AsoyContext';
import { useAuth } from './auth/Authentication';
import { useCache } from './CacheProvider';
const backendUrl = process.env.REACT_APP_API_URL;


const AsoyProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [asoyData, setAsoyData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { token } = useAuth();
  const isAuthenticated = !!token;
  const { get, set, remove } = useCache();

  const fetchData = useCallback(async () => {
    if (!isAuthenticated) return;

    const cachedData = get('asoy-all');
    if (cachedData) {
      setAsoyData(cachedData);
      return;
    }

    try {
      const response = await axios.get(`${backendUrl}/asoy-all/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAsoyData(response.data);
      set('asoy-all', response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }, [isAuthenticated, token, get, set]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refreshData = () => {
    remove('asoy-all');
    fetchData();
  };

  const debouncedSearch = useMemo(() => debounce(setSearchQuery, 300), []);

  const handleSearchChange = (event) => {
    debouncedSearch(event.target.value.toLowerCase());
  };

  useEffect(() => {
    let filtered = asoyData;

    if (searchQuery) {
      filtered = asoyData.filter(asoy => asoy.name && asoy.name.toLowerCase().includes(searchQuery));
    }
    setFilteredData(filtered);
  }, [searchQuery, asoyData]);

  const alphabeticalData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      const nameA = a.name ? a.name.toLowerCase() : '';
      const nameB = b.name ? b.name.toLowerCase() : '';
      return nameA.localeCompare(nameB);
    });
  }, [filteredData]);

  return (
      <AsoyContext.Provider value={{ filteredData: alphabeticalData, handleSearchChange, refreshData }}>
        {children}
      </AsoyContext.Provider>
  );
};

export default AsoyProvider;
