import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useAuth } from "./Authentication";
import Home from "../pages/Home";
import Reports from "../pages/Reports";
import Login from "../pages/Login";
import AsoyDetails from "../pages/AsoyDetails";
import UserManagement from "../pages/UserManagement";
import BuildingManager from "../pages/BuildingManager";
import TeamManagement from "../pages/TeamManagement";
import AsoyManagement from "../pages/AsoyManagement";
import AsoyProvider from "../AsoyProvider";
import { useLocation } from "react-router-dom";
import IdleTimerComponent from "../utils/IdleTimerComponent"; // Adjust the import path as necessary

const Routes = () => {
  const { token, decodedToken } = useAuth(); // Assuming useAuth() provides both token and decoded token

  const ProtectedLayout = () => {
    const isAuthenticated = !!token; // Convert token presence to a boolean
    const location = useLocation();

    if (!isAuthenticated) {
      // Redirect to login page if the user is not authenticated
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />; // This allows rendering child routes if the user is authenticated
  };

  const AdminLayout = ({ children }) => {
    const location = useLocation();

    if (decodedToken && decodedToken.role !== 1) {
      // Redirect to home page if the user is not an admin
      return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
  };

  // Define the router configuration
  const router = createBrowserRouter([
    {
      path: "/login",
      element: token ? <Navigate to="/" replace /> : <Login />,
    },
    {
      path: "/",
      element: <ProtectedLayout />, // Use the ProtectedLayout to wrap child routes
      children: [
        { index: true, element: <Home /> },
        { path: "raportit", element: <Reports /> },
        { path: "raportit/:asoyId", element: <AsoyDetails /> },
        {
          path: "user-management",
          element: (
            <AdminLayout>
              <UserManagement />
            </AdminLayout>
          ),
        },
        {
          path: "building-manager-management",
          element: (
            <AdminLayout>
              <AsoyProvider>
                <BuildingManager />
              </AsoyProvider>
            </AdminLayout>
          ),
        },
        {
          path: "team-management",
          element: (
            <AdminLayout>
              <AsoyProvider>
                <TeamManagement />
              </AsoyProvider>
            </AdminLayout>
          ),
        },
        {
          path: "asoy-management",
          element: (
            <AdminLayout>
              <AsoyProvider>
                <AsoyManagement />
              </AsoyProvider>
            </AdminLayout>
          ),
        },
        // Add more protected child routes here
      ],
    },
    // If you have more routes that should be accessible without authentication, define them here
  ]);
  // Provide the router configuration using RouterProvider
  return (
    <RouterProvider
      router={router}
      fallbackElement={<IdleTimerComponent></IdleTimerComponent>}
    />
  );
};

export default Routes;
