import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Box, Typography, useMediaQuery } from "@mui/material";
import theme from "../styles/theme";
import { useNavigate } from "react-router-dom";
import CustomPieChart from "../utils/CustomPieChart";
import { useCache } from "../CacheProvider";
import CustomTable from "../styles/CustomTable";
const backendUrl = process.env.REACT_APP_API_URL;


const MaintenancesAll = () => {
  const [rows, setRows] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const navigate = useNavigate();
  const { get, set } = useCache();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchMaintenanceDueData = useCallback(async () => {
    let maintenanceData = get("maintenance-due");
    if (!maintenanceData) {
      try {
        const response = await axios.get(`${backendUrl}/elements/maintenance-due/`);
        maintenanceData = response.data;
        set("maintenance-due", maintenanceData);
      } catch (error) {
        console.error("Error fetching maintenance due data:", error);
        return;
      }
    }

    maintenanceData = maintenanceData.map((item) => ({
      ...item,
      lastRepairedYear: item.lastRepairedYear || null,
    }));

    maintenanceData.sort((a, b) => {
      if (a.maintInterval === 0 && b.maintInterval !== 0) return 1;
      if (a.maintInterval !== 0 && b.maintInterval === 0) return -1;
      return 0;
    });

    setRows(maintenanceData);

    const maintenanceCounts = {};
    const maintenanceIds = {};
    maintenanceData.forEach((element) => {
      maintenanceCounts[element.asoyName] = (maintenanceCounts[element.asoyName] || 0) + 1;
      maintenanceIds[element.asoyName] = element.asoyId;
    });

    const sortedCounts = Object.entries(maintenanceCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);

    const chartData = sortedCounts.map(([asoyName, count]) => ({
      id: maintenanceIds[asoyName],
      label: asoyName,
      value: count,
    }));

    setPieChartData(chartData);
  }, [get, set]);

  useEffect(() => {
    fetchMaintenanceDueData();
  }, [fetchMaintenanceDueData]);

  const handleRowClick = (row) => {
    navigate(`/raportit/${row.asoyId}`);
  };

  const columns = [
    { id: "asoyName", label: "Taloyhtiö" },
    { id: "element", label: "Elementti" },
    { id: "lastRepairedYear", label: "Viimeksi korjattu", align: "right" },
    { id: "maintInterval", label: "Huoltoväli (Vuosia)", align: "right" },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5">Huollot tänä vuonna</Typography>
      <CustomPieChart data={pieChartData} navigate={navigate} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: theme.palette.background.default,
          marginLeft: isSmallScreen ? "-60px" : "0px",
          width: isSmallScreen ? "100vw" : "auto",
        }}
      >
        <CustomTable
          columns={columns}
          rows={rows}
          onRowClick={handleRowClick}
          hoverBackgroundColor={theme.palette.action.hover}
        />
      </Box>
    </Box>
  );
};

export default MaintenancesAll;
